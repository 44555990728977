import { AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, QueryList, Renderer2, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { BackendService } from '../services/backend.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { jqxNotificationComponent } from 'jqwidgets-ng/jqxnotification';
import { environment } from 'src/environments/environment';
import FroalaEditor from 'froala-editor';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ColorCodes, transcriptionrequest } from '../models/apiRequestModels';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import axios from 'axios';
import { FileAccessService } from '../services/fileaccess.service';
import 'froala-editor/js/plugins/lists.min.js';
import { FindReplaceDialogComponent } from '../tables/find-replace-dialog/find-replace-dialog.component';
import { Action } from 'rxjs/internal/scheduler/Action';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { Condition } from 'selenium-webdriver';
import { FootPedalService } from '../services/foot-pedal.service';
import { Subscription } from 'rxjs';

declare var tippy: any;
let dropdownspeakerList: any;
let drpoptions = {};
@Component({
  selector: 'app-transcription',
  templateUrl: './transcription.component.html',
  styleUrls: ['./transcription.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TranscriptionComponent implements OnInit, OnDestroy, AfterViewInit {

  private footPedalSubscription: Subscription;
  findReplaceForm: FormGroup;
  currentIndex = 0;
  foundMatches = [];
  cueArray = {};
  player: any;
  manualTimeUpdatehandlerInterval;
  currentTime: number = 0;
  transcription: string;
  editorContent: string = '';
  fileformat: string;
  idxofelm: any;
  editorInstance = [];
  cutOperationExecuted: boolean = false;
  findAndReplace: boolean = false;
  progress: number;
  filesize: number;
  preferences = {
    autoPause: true,
    skipWord: true,
    showActionBubble: true,
    autoSave: true,
    smartCapitalization: true,
    smartParagraphSplitting: true,
    copyIncludeTimecodes: true,
    copyIncludeSpeaker: true
  };
  transcriptionData = [];
  parasCompletelyDeleted: any[] = [];
  isVideoPaused: boolean = true;
  vidTag: any = 0;
  autoStartPaused: boolean = false;
  keySet: any = '';
  tabs = {
    file: true,
    edit: false,
  };
  hotKeys: any = {};
  jobData: any;
  private intervalId: any;
  counter: number = 0;
  audioPlayer: any;
  NetScribefootPedalMSIURL: string = environment.footPedalDownloadUrl;
  // @ViewChildren(FroalaEditor) editors!: QueryList<FroalaEditor>;
  @Input() fileToPlay: string;
  @ViewChild('msgNotification', { static: false }) msgNotification: jqxNotificationComponent;
  @ViewChild('audioRef') audioRef: ElementRef;
  // @ViewChild('editorElement', { static: false }) editorElement: ElementRef;
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  @ViewChild(FroalaEditor) myfroalaEditor: FroalaEditor;
  @ViewChild('myAudio') myAudio: ElementRef<HTMLAudioElement>;
  @ViewChild('audioPlayer') audioPlayerRef: ElementRef;
  @ViewChild('customWindow', { static: false }) customWindow: jqxWindowComponent;

  // @ViewChild('froalaeditor') froalaeditor:FroalaEditor;
  IsVideo: boolean;
  IsFilePlay: boolean;
  IsAudioPlay: boolean;
  IsVideoPlay: boolean;
  height;
  adjustableHeight = 120;
  fileName: string;
  NsJobId: number;
  NcJobId: string;
  private audioplay: any;
  selectedColor: string = "#0000FF";
  colorObj: ColorCodes;
  isColorVisible = true;
  allowTranscriptEditing = false;
  isEditingEnabled = false;
  lastNonStrikePos = 0;
  findWhatText: string = '';
  noOfMatchesPerEditor = [];
  replaceWithText: string = '';
  editHistoryLog: any = [];
  startPosition: any = 0;
  endPosition: any = 0;
  color = new FormControl('', [Validators.nullValidator]);
  speakerName: string = '';
  myviqfroalaEditor: FroalaEditor;
  alertMode: string;
  speakerList = [];
  isjobdataTooltipVisible: boolean = false;
  EditorAutoSaveInterval: any;
  status: string;
  isAutoSaveMessage = false;
  togglePlayPause = false;
  isToggleEnable = false;

  //Constructor
  constructor(private backendService: BackendService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private fileaccess: FileAccessService, private renderer: Renderer2, private footPedalService: FootPedalService) {
    this.color.valueChanges.subscribe(c => {
      this.colorObj = <ColorCodes><unknown>c;
      this.selectedColor = this.colorObj.colorCode;

    });
  }

  ngAfterViewInit(): void {
    this.customWindow.close();
    //initializing TIPPY tooltip
    setTimeout(() => {
      tippy('[data-tippy-content]');

    }, 1000);
  }
  onProgressUpdate(newProgress: number) {
    this.progress = newProgress;
  }
  handleFootPedalEvent(event: string): void {
    this.audioPlayer = this.audioPlayerRef.nativeElement;
    console.log("handleFootPedalEvent ...", event);
    const parts = event.split('-');
    const pressed = parts[0]; // "footpedal.left.pressed"
    const number = parts[1];
    if (number == '1972' || number == '13218') {
      this.isToggleEnable = true
    } else {
      this.isToggleEnable = false;
    }
    if (!this.isEditingEnabled) {
      return;
    }
    switch (pressed) {
      case 'footpedal.center.pressed':
        this.play();
        break;
      case 'footpedal.center.released':
        this.pause();
        break;
      case 'footpedal.right.pressed':
        this.forward();
        break;
      case 'footpedal.left.pressed':
        this.rewind();
        break;
      default:
      //console.log('Unknown event:', event);
    }
  }

  // Media control actions
  play(): void {
    console.log('Playing the media');
    if (this.isToggleEnable) {
      console.log("this is olympus");
      this.togglePlayPause = !this.togglePlayPause;
      if (this.togglePlayPause) {
        this.audioPlayer.currentTime = this.audioPlayer.currentTime - 3;
        this.audioPlayer.play();
      }
      else {
        this.audioPlayer.pause();
      }
    }
    else {
      console.log("this is infinity");

      if (this.audioPlayer.paused)
        this.audioPlayer.currentTime = this.audioPlayer.currentTime - 3;
      this.audioPlayer.play();
    }
  }

  pause(): void {
    console.log('Pausing the media');
    // Implement your media player's pause logic here
    this.audioPlayer.pause();
  }

  forward(): void {
    console.log('Skipping forward');
    // Implement your forward logic here
    this.audioPlayer.currentTime = this.audioPlayer.currentTime + 3;
  }

  rewind(): void {
    console.log('Rewinding');
    // Implement your rewind logic here
    this.audioPlayer.currentTime = this.audioPlayer.currentTime - 3;
  }

  ngOnInit() {

    this.IsVideo = false;
    this.getHeight();
    this.route.queryParams.subscribe((queryParams) => {
      const encodedObject = queryParams['data'];
      const jobdatastring = JSON.parse(decodeURIComponent(encodedObject));
      this.status = jobdatastring.status;
      // Retrieve the encoded object from the URL
      if (encodedObject) {
        // Decode the object from the query parameter
        this.allowTranscriptEditing = (localStorage.getItem("allowTranscriptEditing") == 'true' && this.status == "DRAFTAVAILABLE") ? true : false;
        this.IsFilePlay = false;
        this.NsJobId = Number(jobdatastring.nsJboId);
        this.NcJobId = jobdatastring.jobId;
        this.fileToPlay = "";
        this.speakerList = [];
        this.getaudiofile(jobdatastring);
        this.getedittranscription();
        //initializing TIPPY tooltip

        dropdownspeakerList = [];
        dropdownspeakerList = this.speakerList;
        dropdownspeakerList.forEach((speaker) => {
          drpoptions[speaker.name] = speaker.id;
        });
        this.registerFroalaEditorCommands();

      }
    });
    this.findReplaceForm = new FormBuilder().group({
      findText: new FormControl(''),
      replaceText: new FormControl(''),
      isMatchCase: new FormControl(''),
      isMatchWord: new FormControl('')
    });

    this.findReplaceForm.get('findText')?.valueChanges.subscribe(value => {
      console.log('Value Changed:', value);
      this.currentIndex = 0;
      this.foundMatches = [];
    });
    //for global Foot Pedal events 
    this.footPedalSubscription = this.footPedalService
      .getFootPedalEvents()
      .subscribe((event) => this.handleFootPedalEvent(event))
  }

  jobDataContent = '';
  enablejobtoggleTooltip() {
    // this.getJobTippyContent();
    this.jobDataContent = this.jobData.map(item => item.content).join('<br>');
    this.isjobdataTooltipVisible = !this.isjobdataTooltipVisible;
  }
  async getJobData() {
    this.jobData = [
      // { id: 1, content: "NSJOOB ID: "+ this.NsJobId }, 
      { id: 2, content: "NCJOB ID: " + this.NcJobId },
      { id: 3, content: "Audio FileName: " + this.fileName }
    ]
    // this.jobData = tippyContentList.toString();
  }
  getJobTippyContent() {
    return this.jobData.map(item => item.content).join('\n');
  }

  async getaudiofile(jobdatastring: any) {
    var NSJobId = Number(jobdatastring.nsJboId);
    var NCJobId = jobdatastring.jobId;
    // this.fileName = "Claim12345-JohnDoe.mp3;"
    await this.backendService.getEditorMediaURL(NSJobId, NCJobId).toPromise().then(audiodata => {
      let audioresult = this.backendService.getJSONResponse(audiodata);
      this.IsFilePlay = true;
      this.fileToPlay = audioresult.data.audioUrl;
      this.fileName = audioresult.data.mediaFileName;
      // this.fileformat = audioresult.data.mediaFileName.split(/[.;+_]/).pop().toLowerCase();
      this.fileformat = audioresult.data.fileFormat;
      if (this.fileformat == ".mp4") {
        this.IsVideo = true;
      }
      else {
        this.IsVideo = false;
      }
      this.getJobData();

    })
      .catch(error => {
        // Handle any errors here
      });
  }

  sendNotification(message, messageType) {
    this.msgNotification.elementRef.nativeElement.querySelector('.jqx-notification-content').innerHTML = message;
    this.msgNotification.template(messageType);
    this.msgNotification.open();
  }

  onEditorContentChanged(content: string) {
    this.editorContent = content;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.height = event.target.innerHeight - this.adjustableHeight;
  }

  getHeight() {
    this.height = (window.screen.height - this.adjustableHeight);
    window.dispatchEvent(new Event('resize'));
  }

  initializeEditor(initeditor: any) {
    initeditor.initialize();
    this.myviqfroalaEditor = initeditor.getEditor();
    // this.myfroalaEditor = 
    // this.registerFroalaEditorCommands();
  }

  public options: Object = {
    key: environment.froalaEditorLicenseKey,
    toolbarSticky: true,
    contenteditable: false,
    // multiLine: false,
    // pastePlain: true,
    // htmlUntouched: true,
    toolbarInline: true,
    toolbarVisibleWithoutSelection: false,
    // // 'bold', 'italic', 'underline', 
    // toolbarSticky: true,
    listAdvancedTypes: false,
    toolbarButtons: ['bold', 'italic', 'underline', 'formatUL', 'formatOL', '|', 'add-speaker', '|', 'undo', 'redo', 'allCaps', 'find-replace'],
    // colorsBackground: ['#61BD6D', '#1ABC9C', '#54ACD2', 'REMOVE'], 
    useClasses: true,
    tabSpaces: 4,
    pluginsEnabled: ['lists'],
    // colorsHEXInput: false,
    // shortcutsEnabled: [],
    // shortcutsHint: false,
    htmlAllowedAttrs: ['data-speaker-id', 'data-speaker-first-name', 'weight', 'style', 'audio-position', 'audio-end-position', 'data-is-content-template', 'class', 'id'],
    events: {
      'initialized': function () {
        this.edit.off();
        const editorInstance = this;
        // Prevent toolbar on double click (left mouse)
        editorInstance.events.on('mousedown', function (event) {
          if (event.detail === 2 && event.button === 0) { // double click with left mouse button
            event.preventDefault();
            event.stopPropagation();
            editorInstance.toolbar.hide();
          }
        });
        editorInstance.el.addEventListener('contextmenu', function (event) {
          event.preventDefault();
          editorInstance.toolbar.showInline(event.pageX, event.pageY);
        });
      },
      'keydown': (event) => {
        console.log("Event::", event);
        console.log("event.originalEvent::", event.originalEvent.key);
        // const audioPlayer = this.audioPlayerRef.nativeElement;
        if (event.which === 13) {
          const selection = window.getSelection();
          let div = document.createElement('div');
          if (selection.focusNode.parentElement.parentElement.hasAttribute('data-speaker-id')) {
            selection.focusNode.parentElement.parentElement.remove()
          }
        }
        if (event.ctrlKey && event.shiftKey && event.key === 'U') {
          console.log("All Caps hot key");
          this.toggleAllCaps();
          event.preventDefault();
        }
        if (event.ctrlKey && event.key === 'h') {
          console.log("Find & Replace hot key");
          this.toggleFindReplace();
          event.preventDefault();
        }
        //Foot pedal key events -- Infinity/Olympus
        // if (event.key === 'F9' || event.originalEvent.key === 'MediaPlayPause') {
        //   console.log("F9/MediaPlayPause  FootPedal Center key");

        //   if (!audioPlayer.paused) {
        //     audioPlayer.pause();
        //   }
        //   else{
        //   var sec = audioPlayer.currentTime;
        //   this.adjustAudioPlayerTime(sec, -3);
        //   }
        //   event.preventDefault();
        // }
        // if (event.key === 'F8' ||  event.originalEvent.key === 'MediaTrackNext') {
        //   console.log("F8/MediaTrackNext FootPedal right key");
        //   var sec = audioPlayer.currentTime;
        //   this.adjustAudioPlayerTime(sec, 3);
        //   event.preventDefault();
        // }
        // if (event.key === 'F7' ||  event.originalEvent.key === 'MediaTrackPrevious') {
        //   console.log("F7/MediaTrackPrevious FootPedal left key");
        //   var sec = audioPlayer.currentTime;
        //   this.adjustAudioPlayerTime(sec, -3);
        //   event.preventDefault();
        // }
      },
      'contentChanged': (e, editor) => {
        let div = document.createElement('div');
        document.querySelectorAll(' span.bouncingBall').forEach((bouncingBall) => {
          const parentSpan = bouncingBall.parentElement;
          let text = bouncingBall.textContent;
          let textNode = document.createTextNode(text);
          parentSpan.replaceChild(textNode, bouncingBall);
        });
      },
    }
  }

  adjustAudioPlayerTime(sec: number, AddSeconds: number) {
    const audioPlayer = this.audioPlayerRef.nativeElement;
    audioPlayer.currentTime = sec + AddSeconds;
    audioPlayer.play();
  }
  onClick(index) {
    const target = index.target as HTMLElement;
    const audioPosition = target.getAttribute('audio-position');
    if (audioPosition) {
      var milliseconds = index.srcElement.getAttribute('audio-position') == null ? window.getSelection().getRangeAt(0).commonAncestorContainer.parentElement.getAttribute('audio-position') : index.srcElement.getAttribute('audio-position');
      const seconds = milliseconds / 1000;
      const audioPlayer = this.audioPlayerRef.nativeElement;
      audioPlayer.currentTime = seconds;
      if (!audioPlayer.paused) {
        audioPlayer.pause();
      }
      // Optionally track the clicked element (idxofelm) for further reference
      this.idxofelm = index;
    }
  }
  registerFroalaEditorCommands() {
    FroalaEditor.RegisterCommand('find', {
      title: 'Find',
      icon: 'search',
      focus: false,
      undo: true,
      refresh: ($btn) => {
        // refreshCallback($btn);
      },
      callback: (command, editor, editorInstanceIndex) => {
        let content = editor.html.get();
        let index = 0;
        let word = this.findWhatText;
        let exp = new RegExp('\\b(' + word + ')\\b', 'gi');
        var regex1 = /<span [^>]*?class="findNext"[^>]*>(.*?)<\/span>/gi;
        var regex = /<\/?mark>/gi;
        content = content.replace(regex1, '$1');
        content = content.replace(regex, "");
        content = content.replace(/<\/?mark>/gi, ''),
          content = content.replace(exp, (m) => {
            index++;
            return '<mark>' + m + '</mark>';
          });
        this.noOfMatchesPerEditor[editorInstanceIndex] = index;
        editor.html.set(content);
        //clickCallback(content);
      },
    });

    FroalaEditor.RegisterCommand("find-next", {
      title: "Find-Next",
      icon: "search",
      focus: false,
      undo: true,
      refreshAfterCallback: false,
      refresh: ($btn) => {
        // refreshCallback($btn);
      },
      callback: (e, editor, index) => {
        let content = editor.html.get();
        let word = this.findWhatText;
        let exp = new RegExp("\\b(" + word + ")\\b", "gi");
        let checkIndex = 0;
        content = content.replace(exp, (m) => {
          checkIndex++;
          if (checkIndex === index)
            return (
              '<span class="findNext" style="color:red;">' + m + "</span>"
            );
          else return m;
        });
        editor.html.set(content);
      }
    });

    //FroalaEditor.DefineIconTemplate('find-replace', '<i class="material-icons-outlined">record_voice_over</i>');
    //FroalaEditor.DefineIcon('find-replace', { NAME: 'Replace', template: 'find-replace' });
    FroalaEditor.RegisterCommand('find-replace', {
      title: 'Find-Replace',
      icon: '<i class="material-icons">search</i>',  // Material Icon for search
      focus: true,
      undo: true,
      refresh: ($btn) => {
        // Optional: refreshCallback($btn);
      },
      callback: (command, editor, index) => {
        this.toggleFindReplace();
      }
    });

    FroalaEditor.RegisterCommand('find-replace-all', {
      title: 'Find-replce-all',
      icon: 'search',
      focus: true,
      undo: true,
      refresh: ($btn) => {
        // refreshCallback($btn);
      },
      callback: (command, editor, index) => {
        let content = editor.html.get();
        let word = this.findWhatText;
        let replace = this.replaceWithText;
        let exp = new RegExp('\\b(' + word + ')\\b', 'gi');
        var regex1 = /<span([^>]*?class="findNext"[^>]*>.*?)<\/span>/gi;
        var regex = /<\/?mark>/gi;
        let matchFound = false;
        let count = 0;
        content = content.replace(regex1, word);
        content = content.replace(regex, "");
        content = content.replace(exp, (m) => {
          this.idxofelm = index;
          matchFound = true;
          count++;
          return replace;
        });
        editor.html.set(content);
        if (matchFound) {
          //this.addEditHistory(index, editor.html.get(true))
        }
      },
    });

    FroalaEditor.RegisterCommand('close-find-replace', {
      title: 'Close-Find-replce-all',
      icon: 'search',
      focus: true,
      undo: true,
      refresh: ($btn) => {
        // refreshCallback($btn);
      },
      callback: (command, editor) => {
        let content = editor.html.get();
        var regex1 = /<span [^>]*?class="findNext"[^>]*>(.*?)<\/span>/gi;
        content = content.replace(regex1, '$1');
        content = content.replace(/<\/?mark>/gi, ''),
          editor.html.set(content);
      },
    });

    FroalaEditor.RegisterCommand('remove-find-next-marker', {
      title: 'Close-Find-replce-all',
      icon: 'search',
      focus: true,
      undo: true,
      refresh: ($btn) => {
        // refreshCallback($btn);
      },
      callback: (command, editor) => {
        let content = editor.html.get();
        var regex1 = /<span [^>]*?class="findNext"[^>]*>(.*?)<\/span>/gi;
        content = content.replace(regex1, '$1');
        editor.html.set(content);
      },
    });

    FroalaEditor.DefineIconTemplate('playMedia', '<i class="viq-icon viq-play-circle-fill"></i>');
    FroalaEditor.DefineIcon('play_selected_text', { NAME: 'Play', template: 'playMedia' });
    FroalaEditor.RegisterCommand('play_selected_text', {
      title: 'Play Selected Text',
      focus: false,
      undo: false,
      refreshAfterCallback: false,
      callback: () => {
        this.startPosition = this.myviqfroalaEditor.selection.element().getAttribute('audio-position');
        this.endPosition = this.myviqfroalaEditor.selection.endElement().getAttribute('audio-end-position')
        //this.playTheMedia();
      }
    });

    FroalaEditor.DefineIconTemplate('dictionary', '<i class="viq-icon viq-bookmark-line"></i>');
    FroalaEditor.DefineIcon('add_to_dictionary', { NAME: 'Dictionary', template: 'dictionary' });
    FroalaEditor.RegisterCommand('add_to_dictionary', {
      title: 'Add To Dictionary',
      focus: false,
      undo: false,
      refreshAfterCallback: false,
      callback: () => {
        // this.addToDictionary();
      }
    });

    //'add-speaker'
    FroalaEditor.DefineIconTemplate('speaker', '<i class="material-icons-outlined">record_voice_over</i>');
    FroalaEditor.DefineIcon('add-speaker', { NAME: 'Add Speaker', template: 'speaker' });
    FroalaEditor.RegisterCommand('add-speaker', {
      title: 'Add Speaker',
      type: 'dropdown',
      focus: false,
      undo: false,
      refreshAfterCallback: true,
      options: { "TEST": "TEST" },
      refresh: function ($btn) {
      },
      refreshOnShow: function ($btn, $dropdown) {
        drpoptions = {};
        dropdownspeakerList.forEach((speaker) => {
          if (!drpoptions.hasOwnProperty(speaker.name)) {
            drpoptions[speaker.name] = speaker.id;
          }
        });
        var editorInstance = this;
        var list: any;
        list = $dropdown.find('ul.fr-dropdown-list');
        var listItems = "";

        // Iterate through the updated options and build list items
        for (var name in drpoptions) {
          if (drpoptions.hasOwnProperty(name)) {
            var value = drpoptions[name];
            listItems += buildListItem(name, value);
          }

        }
        listItems += addnewsapeakerItem("Add New Speaker", "add-new-speaker");
        function buildListItem(text, value) {
          return `<li role="presentation"><a data-cmd="add-speaker"  role="option" class="fr-command" data-param1="${value}" data-cmd="${value}"><i class="icon d-flex align-items-center justify-content-center viq-icon viq-user-line"></i>&nbsp${text}</a></li>`;
        }
        function addnewsapeakerItem(text, value) {
          return `<li role="presentation"><a data-cmd="add-speaker"  role="option" class="fr-command" data-param1="${value}" data-cmd="${value}"><i class="icon d-flex align-items-center justify-content-center viq-icon viq-add-user-line"></i>&nbsp${text}</a></li>`;
        }
        // Append the new list items to the dropdown
        list.empty();
        list.append(listItems);
        if (!editorInstance.selection.inEditor()) {
          editorInstance.selection.setAtEnd(editorInstance.$el.get(0));
          editorInstance.selection.restore();
        }
      },
      callback: (event, val, param) => {
        this.selectSpeaker(val);
      }
    });


    FroalaEditor.DefineIconTemplate('highlight', '<i class="viq-icon viq-highlighter-line"></i>');
    FroalaEditor.DefineIcon('highlight_text', { NAME: 'Highlight', template: 'highlight' });
    FroalaEditor.RegisterCommand('highlight_text', {
      title: 'Highlight',
      focus: false,
      undo: false,
      refreshAfterCallback: false,
      callback: () => {
        this.addHighlightClass();
      }
    });



    FroalaEditor.RegisterCommand('remove-bouncing-ball', {
      title: 'Remove-Bouncing-Ball',
      icon: 'search',
      focus: true,
      undo: true,
      refresh: ($btn) => {
        // refreshCallback($btn);
      },
      callback: (command, editor) => {
        let content = editor.html.get();
        var regex1 = /<span [^>]*?class="bouncingBall"[^>]*>(.*?)<\/span>/gi;
        content = content.replace(regex1, '$1');
        editor.html.set(content);
      },
    });

    FroalaEditor.RegisterCommand('remove-nbsp', {
      title: 'Remove-NBSP',
      icon: 'search',
      focus: true,
      undo: true,
      refresh: ($btn) => {
        // refreshCallback($btn);
      },
      callback: (command, editor) => {
        let content = editor.html.get();
        var regex1 = /&nbsp;/gi;
        content = content.replace(regex1, '');
        editor.html.set(content);
      },
    });

    //FroalaEditor.DefineIconTemplate('allCaps', '<i class="material-icons-outlined">format_size</i>');
    //FroalaEditor.DefineIcon('allCaps_text', { NAME: 'allCaps', template: 'allCaps' });
    FroalaEditor.RegisterCommand('allCaps', {
      title: 'All Caps',
      icon: '<i class="material-icons">text_fields</i>',
      focus: true,
      undo: true,
      //refreshAfterCallback: false,
      callback: (e, editor) => this.toggleAllCaps()
    });

  }
  commandName = 'placeholders';
  iconName = this.commandName + 'Icon';
  buildListItem = function (name, value) {
    // Depending on prior validation, escaping may be needed here.
    return '<li><a class="fr-command" data-cmd="' + this.commandName +
      '" data-param1="' + value + '" title="' + name + '">' +
      name + '</a></li>';
  };
  // executeOpenMenuCommand() {
  //   // Execute the custom command to open the mat-menu
  //   FroalaEditor.events.emit('commands.custom.openMenu');
  // }
  addNewSpeaker(action, i, speakerSelected = null) {
    if (action === 'work') {
      if (this.speakerName.trim()) {

      } else {
        this.sendNotification('Please provide a speaker name.', "success");
      }
    } else {
      this.speakerName = '';
    }
  }
  trackByMethod(index: number, el: any): number {
    return el.id;
  }
  addeditspeakers(event) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(this.transcription, 'text/html');
    let spanElements = doc.querySelectorAll('span.srword');
    let pElements = doc.querySelectorAll('p');
    let currentselectionSapnid = "";

    var currentselectionSapn = window.getSelection().anchorNode.parentElement.parentNode
    var currentselectionSapntext = window.getSelection().anchorNode.textContent

    //  var currentselectionSapnid = window.getSelection().anchorNode.parentElement.parentNode
    const parentElement = (window.getSelection().anchorNode.parentElement as HTMLElement)?.parentNode as HTMLElement;
    if (parentElement) {
      const idAttribute = parentElement.getAttribute('data-speaker-id');
      currentselectionSapnid = parentElement.getAttribute('data-speaker-id');
      if (idAttribute) {
        console.log('Found an element with id:', idAttribute);
      }
    }
    var oldSpeakerobj = {
      id: currentselectionSapnid,
      name: currentselectionSapntext
    }
    var newSpeakerobj = {
      id: currentselectionSapnid,
      name: "AJAY"
    }
  }
  selectSpeaker(newspeaker) {
    if (newspeaker === "add-new-speaker") {
      let currentselectionSapnid;
      var currentselectionSapn = window.getSelection().anchorNode.parentElement.parentNode
      let currentselectionSapntext = window.getSelection().anchorNode.textContent.trim()
      currentselectionSapnid = window.getSelection().anchorNode.parentElement.getAttribute('data-speaker-id')
      const parentElement = (window.getSelection().anchorNode.parentElement as HTMLElement)?.parentNode as HTMLElement;
      if (!currentselectionSapnid) {
        //  var currentselectionSapnid = window.getSelection().anchorNode.parentElement.parentNode
        if (parentElement) {
          const idAttribute = parentElement.getAttribute('data-speaker-id');
          currentselectionSapnid = parentElement.getAttribute('data-speaker-id');
          if (idAttribute) {
            console.log('Found an element with id:', idAttribute);
          }
        }
      }
      if (currentselectionSapntext.length > 1 && currentselectionSapntext.length <= 100) {


        if (currentselectionSapntext && currentselectionSapnid == null) {

          Swal.fire({
            title: "Do you want to add <p style='color:red;'><i> " + currentselectionSapntext + "</i></p> as new speaker",
            showCancelButton: true
          }).then((result) => {
            if (result.dismiss) {

            }
            else if (result.isConfirmed) {
              let isSpeakerExists = this.speakerList.find((item) => {
                if (item.name.toLowerCase() == currentselectionSapntext.toLowerCase()) {
                  return true
                }
              })
              if (isSpeakerExists) {
                Swal.fire("Speaker <p style='color:red;'> " + currentselectionSapntext + "</i></p> already exists", '', 'info');

              } else {
                if (currentselectionSapntext) {
                  var newspeakername = currentselectionSapntext;
                  const selection = window.getSelection();
                  if (selection && selection.rangeCount > 0) {
                    const range = selection.getRangeAt(0);
                    const styledElement = document.createElement('p');
                    styledElement.innerHTML = `<span style="cursor: pointer!important;" data-speaker-id="${newspeakername}" data-speaker-first-name="${newspeakername}" class="speaker speaker-${newspeakername}"><strong>${newspeakername}:&nbsp;</strong></span>`;
                    //for bug 35948
                    let endContainer = range.endContainer as Element
                    if (range.commonAncestorContainer.parentElement.hasAttribute('audio-position')) {
                      if (range.commonAncestorContainer.parentElement.previousElementSibling == null || range.commonAncestorContainer.parentElement.previousElementSibling.textContent == '\u200B') {
                        range.commonAncestorContainer.parentElement.remove();
                        range.commonAncestorContainer.parentNode.insertBefore(styledElement, range.commonAncestorContainer)
                        //
                        if (range.endContainer.textContent.trim() == '\u200B') {
                          range.commonAncestorContainer.parentNode.insertBefore(styledElement, range.commonAncestorContainer)
                          let isEmptytag = range.endContainer as Element
                          isEmptytag.remove()
                        }
                        // ended 
                      }
                      else {
                        // range.startContainer.parentElement.remove()
                        let startContainer = range.commonAncestorContainer as Element
                        let a = styledElement.textContent
                        let b = startContainer
                        const isSameSpeakerName = (a, b) => {
                          const cleanString = str => str.replace(/\W/g, '').trim();
                          return cleanString(styledElement.textContent) === cleanString(b);
                          // if(startContainer.parentElement.tagName == 'SPAN' &&  startContainer.parentElement.querySelector('p')){
                        };
                        if (isSameSpeakerName && !startContainer.parentElement.parentElement.nextElementSibling.firstElementChild.hasAttribute('data-speaker-id')) {
                          range.commonAncestorContainer.parentNode.insertBefore(styledElement, range.commonAncestorContainer.nextSibling)

                          startContainer.parentElement.firstElementChild.remove();
                          startContainer.parentElement.remove()
                          range.commonAncestorContainer.parentNode.insertBefore(styledElement, range.commonAncestorContainer.nextSibling)

                        } else {
                          range.commonAncestorContainer.parentNode.insertBefore(styledElement, range.commonAncestorContainer.nextSibling)
                        }
                        // }
                      }

                    }
                    else if (range.commonAncestorContainer == endContainer) {
                      range.commonAncestorContainer.parentNode.insertBefore(styledElement, range.commonAncestorContainer)
                      endContainer.remove()
                    }
                    else {
                      range.deleteContents();
                      range.insertNode(styledElement);
                    }
                    //  
                  }
                  this.speakerList.push({
                    name: newspeakername,
                    id: newspeakername,
                    outerHTML: `<span contenteditable="false" style="cursor: pointer!important" data-speaker-id="${newspeakername}" data-speaker-first-name="${newspeakername}" class="speaker speaker-${newspeakername}"><strong>${newspeakername}: </strong></span>`
                    // outerHTML: `<span style="cursor: pointer!important" data-speaker-id=` + newspeakername + ` data-speaker-first-name=` + newspeakername + ` class="speaker speaker-` + newspeakername + `><b>` + newspeakername + `</b></span>`
                  });
                  this.sendNotification(`New speaker <span style='color:red;'><i> ${newspeakername} </i></span> added.`, 'success');
                }
              }
            }
          });
        }
        else {
          Swal.fire({
            title: "Add Speaker",
            text: "Enter Speaker Name",
            input: 'text',
            showCancelButton: true
          }).then((result) => {
            if (result.value) {
              let newspeakername = result.value;
              let isSpeakerExists = this.speakerList.find((item) => {
                if (item.name.toLowerCase() == newspeakername.toLowerCase()) {
                  return true
                }
              })
              if (!isSpeakerExists) {
                this.speakerList.push({
                  name: newspeakername,
                  id: newspeakername,
                  outerHTML: `<span contenteditable="false" style="cursor: pointer!important" data-speaker-id="${newspeakername}" data-speaker-first-name="${newspeakername}" class="speaker speaker-${newspeakername}"><strong>${newspeakername}: </strong></span>`

                  // outerHTML: `<span style="cursor: pointer!important" data-speaker-id=` + newspeakername + ` data-speaker-first-name=` + newspeakername + ` class="speaker speaker-` + newspeakername + `><b>` + newspeakername + `</b></span>`
                });
                // Swal.fire(`New speaker ${newspeakername} added.`, '', 'info');
                this.sendNotification(`New speaker <span style='color:red;'><i> ${newspeakername} </i></span> added.`, 'success');
              } else {
                Swal.fire("Speaker <p style='color:red;'> " + newspeakername + "</i></p> already exists", '', 'info');
              }
            }
          });
        }
      }
      else {
        if (currentselectionSapntext.trim().length == 0 || currentselectionSapntext.trim() == '\u200B') {
          Swal.fire({
            title: "Add Speaker",
            text: "Enter Speaker Name",
            input: 'text',
            showCancelButton: true
          }).then((result) => {
            if (result.value) {
              let newspeakername = result.value;
              let isSpeakerExists = this.speakerList.find((item) => {
                if (item.name == newspeakername) {
                  return true
                }
              })
              if (!isSpeakerExists) {
                this.speakerList.push({
                  name: newspeakername,
                  id: newspeakername,
                  outerHTML: `<span contenteditable="false" style="cursor: pointer!important" data-speaker-id="${newspeakername}" data-speaker-first-name="${newspeakername}" class="speaker speaker-${newspeakername}"><strong>${newspeakername}: </strong></span>`
                });
                this.sendNotification(`New speaker <span style='color:red;'><i> ${newspeakername} </i></span> added.`, 'success');
              } else {
                Swal.fire("Speaker <p style='color:red;'> " + newspeakername + "</i></p> already exists", '', 'info');
              }
            }
          });
        } else {
          Swal.fire('Speaker length should be more than 1(one) chars and less than 100 chars', '', 'info')
        }

      }
    }
    else {
      let currentselectionSapnid;
      var currentselectionSapn = window.getSelection().anchorNode.parentElement.parentNode
      var currentselectionSapntext = window.getSelection().anchorNode.textContent
      currentselectionSapnid = window.getSelection().anchorNode.parentElement.getAttribute('data-speaker-id')
      const parentElement = (window.getSelection().anchorNode.parentElement as HTMLElement)?.parentNode as HTMLElement;
      if (!currentselectionSapnid) {
        if (parentElement) {
          const idAttribute = parentElement.getAttribute('data-speaker-id');
          currentselectionSapnid = parentElement.getAttribute('data-speaker-id');
          if (idAttribute) {
          }
        }
      }
      const newfoundSpeaker = this.speakerList.find((speaker) => speaker.name === newspeaker);
      const oldfoundSpeaker = this.speakerList.find((speaker) => speaker.id === currentselectionSapnid);
      if (newfoundSpeaker && oldfoundSpeaker) {


        var oldSpeakerobj = {
          id: currentselectionSapnid,
          name: currentselectionSapntext
        }
        var newSpeakerobj = {
          id: currentselectionSapnid,
          name: newspeaker
        }
        if (oldfoundSpeaker.name === newfoundSpeaker.name) {
          Swal.fire('Selected Same Speaker, Please Select Other Speaker', '', 'info')
        }
        else {
          Swal.fire({
            title: 'Do you want to change all the ' + oldfoundSpeaker.name + ' labels to ' + newfoundSpeaker.name,
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Change All',
            denyButtonText: `Only Selected`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              if (newfoundSpeaker) {
                // The object with the specified name was found
                this.addSpeakerToCoreHtmlFile(oldSpeakerobj, newfoundSpeaker, -1)
              } else {
                console.log("Selected Speaker not Correct.");
              }
              Swal.fire('Changed all the Speakers', '', 'success')

            } else if (result.isDenied) {
              parentElement.setAttribute('data-speaker-first-name', newfoundSpeaker.name);
              parentElement.setAttribute('data-speaker-id', newfoundSpeaker.id);
              parentElement.setAttribute('class', "speaker speaker-" + newfoundSpeaker.id);
              parentElement.innerHTML = `<strong>${newfoundSpeaker.name}: </strong>`;
              // this.addSpeakerToCoreHtmlFile(oldSpeakerobj, newfoundSpeaker, 0)
              Swal.fire('Changed the Speaker', '', 'success')
            }
          })
        }

      }
      else if (newfoundSpeaker) {
        Swal.fire({
          title: "Do you want to insert the <p style='color:red;'><i> " + newfoundSpeaker.name + "</i></p>",
          showCancelButton: true,
          confirmButtonText: 'Yes',
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            if (newfoundSpeaker) {
              const selection = window.getSelection();
              // The object with the specified name was found
              if (selection && selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                const styledElement = document.createElement('p');
                styledElement.innerHTML = newfoundSpeaker.outerHTML;
                let firstChild = styledElement.firstChild as Element
                firstChild.setAttribute('contenteditable', 'true');
                firstChild.innerHTML = `<strong>${newfoundSpeaker.name}: </strong>`;
                const endContainer = range.endContainer as Element; // or HTMLElement
                //to remove extra p tag element
                if (range.endContainer.textContent.trim().length == 0) {
                  endContainer.remove()
                  range.deleteContents();
                  range.insertNode(styledElement)
                } else {
                  if (range.commonAncestorContainer.textContent.trim().length <= 1) {
                    let commonAncestorContainer = range.commonAncestorContainer as Element
                    commonAncestorContainer.parentElement.remove()
                    range.commonAncestorContainer.parentNode.insertBefore(styledElement, range.commonAncestorContainer)
                  } else {
                    range.commonAncestorContainer.parentNode.insertBefore(styledElement, range.commonAncestorContainer)
                  }
                }
              }
              //this.addSpeakerToCoreHtmlFile(oldSpeakerobj, newfoundSpeaker, -1)
            } else {
              // The object with the specified name was not found
              console.log("Selected Speaker not Correct.");
            }
            Swal.fire('Speaker Inserted', '', 'success')

          }
        })
      }
    }
  }

  indexOfElm: number = -1;
  addSpeakerToCoreHtmlFile(oldspeakerUpdateTo = null, newspeakerUpdateBy = null, index = -1) {
    const div = document.createElement('div');
    // div.innerHTML = this.transcription;
    div.innerHTML = this.myviqfroalaEditor.html.get();
    if (index === -1) {
      if (oldspeakerUpdateTo && newspeakerUpdateBy) {
        if (!this.isSingleSpeakerTranscription) {
          // replace all and tag and if multispeaker
          let paragraphIndexCounter = -1;
          div.querySelectorAll('p').forEach((x: any) => {
            if (x.innerHTML.includes('data-speaker-id')) {
              paragraphIndexCounter += 1;
              if (x.childNodes[0].getAttribute('data-speaker-id') == oldspeakerUpdateTo.id) {
                x.childNodes[0].setAttribute('data-speaker-first-name', newspeakerUpdateBy.name);
                x.childNodes[0].setAttribute('data-speaker-id', newspeakerUpdateBy.id);
                x.childNodes[0].setAttribute('class', "speaker speaker-" + newspeakerUpdateBy.id);
                x.childNodes[0].innerHTML = `<strong>${newspeakerUpdateBy.name}: </strong>`;
                this.indexOfElm = paragraphIndexCounter;
                // this.addHistoryLog();
                // this.addEditHistory(paragraphIndexCounter, this.editorInstance[paragraphIndexCounter].html.get(true))
              }
            }
          });
        } else {
          this.indexOfElm = 0;
          // for single speaker edit and tag;
          if (div.querySelectorAll('[data-speaker-id]')[0].getAttribute('data-speaker-id') == oldspeakerUpdateTo.id) {
            div.querySelectorAll('[data-speaker-id]')[0].setAttribute('data-speaker-first-name', newspeakerUpdateBy.name);
            div.querySelectorAll('[data-speaker-id]')[0].innerHTML = `<strong>${newspeakerUpdateBy.name}: </strong>`;
            // this.addHistoryLog();
            // this.addEditHistory(0, this.editorInstance[0].html.get(true))
          }

        }
      }
    } else {
      // // for single edit and tag
      let paragraphIndexCounter = -1;
      div.querySelectorAll('p').forEach((x: any) => {
        if (x.innerHTML.includes('data-speaker-id')) {
          paragraphIndexCounter += 1;
          if (paragraphIndexCounter == index) {
            if (x.childNodes[0].getAttribute('data-speaker-id') == oldspeakerUpdateTo.id) {
              x.childNodes[0].setAttribute('data-speaker-first-name', newspeakerUpdateBy.name);
              x.childNodes[0].setAttribute('data-speaker-id', newspeakerUpdateBy.id);
              x.childNodes[0].innerHTML = `<strong>${newspeakerUpdateBy.name}: </strong>`;
              this.indexOfElm = paragraphIndexCounter;
              // this.addHistoryLog();
              // this.addEditHistory(paragraphIndexCounter, this.editorInstance[paragraphIndexCounter].html.get(true))
            }
            return;
          }

        }
      });
    }
    this.transcription = div.innerHTML;
  }

  private getSelectedTextWithStyling(): string {
    const editorElement = this.myviqfroalaEditor.editor;

    if (window.getSelection) {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const clonedSelection = range.cloneContents();
        const div = document.createElement('div');
        div.appendChild(clonedSelection);
        return div.innerHTML;
      }
    }

    return '';
  }
  //ADDING EDIT HISTORY STARTS
  addEditHistory(index, updatedHtml) {
    updatedHtml = updatedHtml.replace('<p>', '').replace('</p>', '');
    if (this.tabs.edit) {
      const logIndex = this.editHistoryLog.findIndex(x => x.paragraphIndex === index);
      if (logIndex == -1) {
        this.editHistoryLog.push({ previousText: this.transcriptionData[index].spanString, updatedText: updatedHtml, paragraphIndex: index, timeStamp: new Date().toISOString() });
      } else {
        this.editHistoryLog[logIndex].updatedText = updatedHtml;
        this.editHistoryLog[logIndex].timeStamp = new Date().toISOString();
      }
    }
  }
  addHighlightClass() {
    this.myviqfroalaEditor.colors.showColorsPopup("backgroundColor");
    // this.editor.selection.clear();
  }
  addUnderline() {
    this.myviqfroalaEditor.commands.underline();
    // this.editorInstance[this.idxofelm].selection.clear();
  }
  generateCueForStrikeThrough() {
    let cuePoint = undefined;
    let innerCuePoints = [];
    this.editorInstance.forEach((editor, instanceIndex) => {
      let div = document.createElement('div');
      div.innerHTML = editor?.html?.get(true);
      const srWordList = div.querySelectorAll('.srword');

      for (let index = 0; index <= srWordList?.length - 1; index++) {
        let span = srWordList[index];
        if (document.getElementById(span.getAttribute('id')).classList.contains('selectionStrike')
          || document.getElementById(span.getAttribute('id')).innerHTML.includes('<strike>')
          || document.getElementById(span.getAttribute('id')).parentElement.nodeName == 'STRIKE'
          || document.getElementById(span.getAttribute('id')).parentElement.nodeName == '<s>'
          || document.getElementById(span.getAttribute('id')).innerHTML.includes('<s>')) {
          if (!cuePoint) {
            if (index == 0 && instanceIndex == 0) {
              cuePoint = 0
            } else if (index == 0 && instanceIndex > 0) {
              cuePoint = this.lastNonStrikePos
            } else {
              cuePoint = srWordList[index - 1].getAttribute('audio-end-position')
            }
          }
          innerCuePoints.push(srWordList[index].getAttribute('audio-end-position'))
        } else {
          this.lastNonStrikePos = +srWordList[index].getAttribute('audio-end-position')
          this.cueArray[cuePoint] = { moveTo: srWordList[index].getAttribute('audio-position') };
          innerCuePoints.forEach((innerCuePoint) => {
            this.cueArray[innerCuePoint] = { moveTo: srWordList[index].getAttribute('audio-position') };
          })
          cuePoint = undefined;
          innerCuePoints = [];
        }
      }

    })
  }

  netscribeLogintoken: string;
  async getnswauthenticate() {
    // this.netscribeLogintoken= localStorage.getItem('nswauthtoken');
    if (!this.netscribeLogintoken)
      await this.backendService.netscribeLogin().pipe().toPromise()
        .then(data => {
          var authresult = this.backendService.getJSONResponse(data);
          this.netscribeLogintoken = authresult.result;
          localStorage.setItem('nswauthtoken', this.netscribeLogintoken);
        })
        .catch(error => {
          console.log("netscribeLogin error:::", error);
        });
  }
  async getedittranscription() {
    this.spinner.show();
    await this.backendService.myviqGetLastDocumentVersionContent(this.NsJobId.toString()).subscribe((result: any) => {
      // var transcriptionresult = this.backendService.getJSONResponse(result);
      //console.log("transcriptionresult: ", transcriptionresult);
      // this.transcription = transcriptionresult.data.result;
      this.transcription = result ? JSON.parse(result.data.result) : null;
      if (!this.transcription) {
        Swal.fire('Unable to fetch the Draft', '', 'error')
      }
      // this.onEditorContentChanged(this.transcription);
      this.spinner.hide();
      this.formatHtmlData(false);

    },
      err => {
        const er = this.backendService.getJSONResponse(err);
        console.log(err);
        if (err.status == 401) {

          this.sendNotification('Token expired, Please reload the page', 'error');
        } else {
          this.sendNotification('Something went wrong', 'error');
        }
        this.spinner.hide();
      })
  }
  prevActiveTextId: any = null;
  holdTranscriptionClikedPara = null;
  isSingleSpeakerTranscription = false;

  speakerStyle: any;

  onAudioPlay(event: any) {
    console.log('Audio started playing');
  }
  onAudioPause(event: any): void {
    const myAudio = document.querySelector('.audioClass') as HTMLAudioElement | null;
    if (myAudio) {
      myAudio.pause(); // Pauses the audio
    }
    let div = document.createElement('div');
    document.querySelectorAll('span.bouncingBall').forEach((bouncingBall) => {
      const parentSpan = bouncingBall.parentElement;
      let text = bouncingBall.textContent;
      let textNode = document.createTextNode(text);
      parentSpan.replaceChild(textNode, bouncingBall);
    });
  }

  onTimeUpdate(event: Event) {
    if (this.isColorVisible) {
      this.isColorVisible = false;
    }

    const audioElement = event.target as HTMLAudioElement;
    this.currentTime = audioElement.currentTime;
    // this.manualTimeUpdatehandlerInterval = setInterval(() => {
    let activePara = null;
    if (this.currentTime && this.transcriptionData.length) {
      const mlsecond = this.currentTime * 1000;
      activePara = this.transcriptionData.find((x, i) => mlsecond >= x.start && mlsecond <= x.end);
      if (activePara) {
        const divForSpeaker = document.createElement('div');
        divForSpeaker.innerHTML = activePara.spanString;
        let srWordList = divForSpeaker.querySelectorAll('.srword');
        if (srWordList.length == 0) {
          srWordList = divForSpeaker.querySelectorAll('[fr-original-class="srword"]');
        }
        srWordList.forEach((span, index) => {
          const start: any = +span.getAttribute('audio-position');
          const end: any = +span.getAttribute('audio-end-position');

          if (mlsecond >= start && mlsecond <= end) {
            if (this.cueArray[end]) {
              this.vidTag.currentTime = this.cueArray[end].moveTo / 1000
            } else {
              if (this.prevActiveTextId && this.preferences.skipWord) {
                if (document.getElementById(this.prevActiveTextId)) {
                  document.getElementById(this.prevActiveTextId).innerHTML = (document.getElementById(this.prevActiveTextId).innerHTML).replace('<span class="bouncingBall" style="color: ' + this.selectedColor + '; font-weight: 700; font-size: 14px;">', '').replace('</span>', '');
                }
              }
              if (document.getElementById(span.getAttribute('id')) && this.preferences.skipWord && !this.tabs['edit']) {
                const audioPlayer = this.audioPlayerRef.nativeElement;
                if (!audioPlayer.paused) {
                  document.getElementById(span.getAttribute('id')).innerHTML = `<span class="bouncingBall" style="color: ` + this.selectedColor + `; font-weight: 700; font-size: 14px;">${document.getElementById(span.getAttribute('id')).innerHTML}</span>`;
                  document.getElementById(span.getAttribute('id')).scrollIntoView({ inline: 'nearest', behavior: 'smooth' });
                  this.prevActiveTextId = span.getAttribute('id');
                }
              }
            }
          }
        });
      }
    } else {
      if (this.prevActiveTextId) {
        if (document.getElementById(this.prevActiveTextId)) {
          document.getElementById(this.prevActiveTextId).innerHTML = document.getElementById(this.prevActiveTextId).innerText;
        }
      }
    }
    //  }, 10)
    this.isVideoPaused = this.vidTag.paused;

  }
  formatHtmlData(editMode = false) {
    const div = document.createElement('div');
    // Create a DOM element to parse the HTML content
    const parser = new DOMParser();
    const doc = parser.parseFromString(this.transcription, 'text/html');
    div.innerHTML = this.transcription;
    let paragraphs = doc.querySelectorAll('p');
    let spanElements = doc.querySelectorAll('span');
    const srWordList = doc.querySelectorAll('.srword');
    spanElements.forEach(element => {
      ///// extract speakers form the transscription
      if (this.speakerList.find((x1: any) => x1.id == element.getAttribute('data-speaker-id')) == undefined) {
        if (element.getAttribute('data-speaker-id') != null) {
          this.speakerList.push({
            name: element.getAttribute('data-speaker-first-name'),
            id: element.getAttribute('data-speaker-id'),
            outerHTML: element.outerHTML
          });
        }
      }


    });
    //removing empty p tags with <br> & used for bouncing ball highliter start
    div.querySelectorAll('p').forEach((item) => {
      const hasEmptyBrAndStyles =
        item.getAttribute('fr-original-style') === "" &&
        item.innerHTML.trim() === '<br fr-original-style="" style="-webkit-tap-highlight-color: transparent; -webkit-font-smoothing: antialiased; text-decoration: none; outline: none; box-sizing: border-box;">';
      const hasNoSpanChild = !item.querySelector('span');
      if (hasNoSpanChild) {
        item.remove();
      }
      if (hasEmptyBrAndStyles) {
        item.remove();// Removes each element that matched the condition
      }
      //removing empty p tags with <br> & used for bouncing ball highliter end
    });

    if (div.innerHTML.includes('data-speaker-id')) {
      console.log('MULTI SPEAKER');
      this.isSingleSpeakerTranscription = false;
    } else {
      console.log('SINGLE SPEAKER');
      this.isSingleSpeakerTranscription = true;
    }

    if (!this.isSingleSpeakerTranscription) {
      div.querySelectorAll('p').forEach((x: any, index) => {
        if (x.innerHTML.includes('data-speaker-id') && !x.getAttribute('data-is-content-template')) {
          if (x.innerHTML.includes('audio-position')) {
            console.log('\n\n********** Multi Speaker Same p tag *******************\n\n**********')
            // to replace the . , and other separator in a span from outside span
            x.childNodes.forEach((element, i) => {
              if (element && element.nodeName != '#text' && element?.getAttribute('audio-position')) {
                if (i + 1 < x.childNodes.length) {
                  if (x.childNodes[i + 1].nodeName == '#text') {
                    const txt = element.innerText;
                    element.innerText = txt + x.childNodes[i + 1].data;
                  }
                }
              } else {
                // element.data = '';
              }
            });
            const span = x.innerHTML.split('</span>');
            span.splice(0, 1);
            const spanString = span.join('</span>');

            const divForSpeaker = document.createElement('div');
            divForSpeaker.innerHTML = spanString;

            let srWordList = divForSpeaker.querySelectorAll('.srword');
            if (srWordList.length == 0) {
              srWordList = divForSpeaker.querySelectorAll('[fr-original-class="srword"]');
            }
            const srWordEnd = srWordList[srWordList.length - 1].getAttribute('audio-end-position');
            if (this.speakerList.find((x1: any) => x1.id == x.childNodes[0].getAttribute('data-speaker-id')) == undefined) {
              this.speakerList.push({
                name: x.childNodes[0].getAttribute('data-speaker-first-name'),
                id: x.childNodes[0].getAttribute('data-speaker-id'),
                outerHTML: x.childNodes[0].outerHTML
              });
            }
            const seconds = x.childNodes[1].getAttribute('audio-position') ? x.childNodes[1].getAttribute('audio-position') : srWordList[0].getAttribute('audio-position');
            const formattedStart = this.getFormatedSecond(seconds / 1000);

            this.transcriptionData.push(
              {
                word: x.innerText,
                confidence: '',
                formattedStart,
                start: x.childNodes[1].getAttribute('audio-position') ? x.childNodes[1].getAttribute('audio-position') : srWordList[0].getAttribute('audio-position'),
                end: srWordEnd ? srWordEnd : null,
                speaker: {
                  name: x.childNodes[0].getAttribute('data-speaker-first-name'),
                  id: x.childNodes[0].getAttribute('data-speaker-id')
                },
                channel: '',
                alternateWords: [],
                spanString,
                speakingPara: false,
                allowActionOnPara: true
              }
            );
          } else {
            // console.log('\n********** Multi Speaker Different p tag *******************\n**********')
            if (div.querySelectorAll('p')[index + 1].innerHTML.includes('audio-position')) {
              const nextNode: any = div.querySelectorAll('p')[index + 1];
              let nextIndex = index + 2; // Start checking from the index after the next one
              //to show bouncing ball on multiple lines in same paragraph (loops till nextline has audio-position)
              while (div.querySelectorAll('p')[nextIndex]?.innerHTML.includes('audio-position')) {
                nextNode.appendChild(div.querySelectorAll('p')[nextIndex]);
                nextIndex++;
              }//to show bouncing ball on multiple lines in same paragraph (loops till nextline has audio-position) end
              nextNode.childNodes.forEach((element, i) => {
                if (element && element.nodeName != '#text' && element.getAttribute('audio-position')) {
                  if (i + 1 < nextNode.childNodes.length) {
                    if (nextNode.childNodes[i + 1].nodeName == '#text') {
                      const txt = element.innerHTML;
                      element.innerHTML = txt + nextNode.childNodes[i + 1].data;
                    }
                  }
                }
                else {
                  // element.data = '';
                }
              });
              const span = nextNode.innerHTML.split('</span>');
              const spanString = span.join('</span>');
              const divForSpeaker = document.createElement('div');
              divForSpeaker.innerHTML = spanString;

              let srWordList = divForSpeaker.querySelectorAll('.srword');
              if (srWordList.length == 0) {
                srWordList = divForSpeaker.querySelectorAll('[fr-original-class="srword"]');
              }
              const srWordEnd = srWordList[srWordList.length - 1].getAttribute('audio-end-position');

              if (this.speakerList.find((x1: any) => x1.id == x.childNodes[0].getAttribute('data-speaker-id')) == undefined) {
                this.speakerStyle = x.childNodes[0].getAttribute('style');
                this.speakerList.push({
                  name: x.childNodes[0].getAttribute('data-speaker-first-name'),
                  id: x.childNodes[0].getAttribute('data-speaker-id'),
                  outerHTML: x.childNodes[0].outerHTML
                });
              }
              const seconds = nextNode.childNodes[0].textContent.trim().length > 0 ? nextNode.childNodes[0].getAttribute('audio-position') : srWordList[0].getAttribute('audio-position');
              const formattedStart = this.getFormatedSecond(seconds / 1000);

              this.transcriptionData.push(
                {
                  word: nextNode.innerText,
                  confidence: '',
                  formattedStart,
                  start: nextNode.childNodes[0].textContent.trim().length > 0 ? nextNode.childNodes[0].getAttribute('audio-position') : srWordList[0].getAttribute('audio-position'),
                  end: srWordEnd ? srWordEnd : null,
                  speaker: {
                    name: x.childNodes[0].getAttribute('data-speaker-first-name'),
                    id: x.childNodes[0].getAttribute('data-speaker-id')
                  },
                  channel: '',
                  alternateWords: [],
                  spanString,
                  speakingPara: false,
                  allowActionOnPara: true,
                  actualIndexAsPerRecieved: index + 1
                }
              );
            } else {

              const nextNode: any = div.querySelectorAll('p')[index + 1];
              if (this.speakerList.find((x1: any) => x1.id == x.childNodes[0].getAttribute('data-speaker-id')) == undefined) {
                this.speakerList.push({
                  name: x.childNodes[0].getAttribute('data-speaker-first-name'),
                  id: x.childNodes[0].getAttribute('data-speaker-id'),
                  outerHTML: x.childNodes[0].outerHTML
                });
              }
              this.transcriptionData.push(
                {
                  word: '',
                  confidence: '',
                  formattedStart: '',
                  start: null,
                  end: null,
                  speaker: {
                    name: x.childNodes[0].getAttribute('data-speaker-first-name'),
                    id: x.childNodes[0].getAttribute('data-speaker-id')
                  },
                  channel: '',
                  alternateWords: [],
                  spanString: '',
                  speakingPara: false,
                  allowActionOnPara: false,
                  actualIndexAsPerRecieved: index + 1
                }
              );
            }
          }
        } else if (!x.innerHTML.includes('data-speaker-id') && x.getAttribute('data-is-content-template')) {
          this.transcriptionData.push(
            {
              word: x.innerText,
              confidence: '',
              formattedStart: '',
              start: null,
              end: null,
              speaker: {
                name: null,
                id: null
              },
              channel: '',
              alternateWords: [],
              spanString: x.innerHTML,
              speakingPara: false,
              allowActionOnPara: false
            }
          )
        }
      });
    }
    /******
     * if single speaker file.
     *
     *
     * */
    if (this.isSingleSpeakerTranscription) {
      console.log('\n\n********** Single speaker *******************\n\n**********')
      div.querySelectorAll('p').forEach((x: any) => {
        if (x.querySelectorAll('[fr-original-class="srword"]').length > 0 || x.querySelectorAll('.srword')[0] > 0) {
          x.childNodes.forEach((element: any, i) => {
            if (element && element.nodeName != '#text' && element?.getAttribute('audio-position')) {
              if (i + 1 < x.childNodes.length) {
                if (x.childNodes[i + 1].nodeName == '#text') {
                  const txt = element.innerHTML;
                  element.innerHTML = txt + x.childNodes[i + 1]['data'];
                }
              }
            } else {
              // element.data = '';
            }
          });
          const span0 = x.querySelectorAll('.srword')[0] ? x.querySelectorAll('.srword')[0] : x.querySelectorAll('[fr-original-class="srword"]');
          const seconds: any = span0 ? x.querySelectorAll('[fr-original-class="srword"]')[0].attributes.getNamedItem('audio-position').value : (x.querySelectorAll('.srword')[0].getAttribute('audio-position') ? x.querySelectorAll('.srword')[0].getAttribute('audio-position') : null);
          const formattedStart = seconds ? this.getFormatedSecond(seconds / 1000) : null;
          const srWordList = x.querySelectorAll('[fr-original-class="srword"]') ? x.querySelectorAll('[fr-original-class="srword"]') : x.querySelectorAll('.srword')
          const srWordEnd = srWordList.length ? srWordList[srWordList.length - 1].getAttribute('audio-end-position') : null;
          let startAudio = "";
          if (x.querySelectorAll('[fr-original-class="srword"]').length > 0) {
            startAudio = x.querySelectorAll('[fr-original-class="srword"]')[0].attributes.getNamedItem('audio-position').value ? x.querySelectorAll('[fr-original-class="srword"]')[0].attributes.getNamedItem('audio-position').value : null
          } else {
            startAudio = x.querySelectorAll('.srword')[0].getAttribute('audio-position') ? x.querySelectorAll('.srword')[0].getAttribute('audio-position') : null
          }
          if (!x.getAttribute('data-is-content-template')) {
            this.transcriptionData.push(
              {
                word: x.innerText,
                confidence: '',
                formattedStart: formattedStart ? formattedStart : null,
                start: startAudio,
                end: srWordEnd ? srWordEnd : null,
                speaker: {
                  name: '(Single Speaker)',
                  id: 'S1'
                },
                channel: '',
                alternateWords: [],
                spanString: x.innerHTML,
                speakingPara: false,
                allowActionOnPara: true
              }
            );
            if (this.speakerList.find((x1: any) => x1.id == 'S1') == undefined) {
              this.speakerList.push({
                name: 'Single Speaker',
                id: 'S1'
              });
            }
          } else {
            this.transcriptionData.push(
              {
                word: x.innerText,
                confidence: '',
                formattedStart: formattedStart ? formattedStart : null,
                start: startAudio,//span0?.getAttribute('audio-position') ? span0.getAttribute('audio-position') : null,
                end: srWordEnd ? srWordEnd : null,
                speaker: {
                  name: null,
                  id: null
                },
                channel: '',
                alternateWords: [],
                spanString: x.innerHTML,
                speakingPara: false,
                allowActionOnPara: false
              }
            );
          }
        }
      })

      if (this.transcriptionData.length == 0) {
        const span0 = div.querySelectorAll('.srword')[0];
        const seconds: any = span0 ? div.querySelectorAll('.srword')[0].getAttribute('audio-position') : null;
        const formattedStart = seconds ? this.getFormatedSecond(seconds / 1000) : null;
        const srWordList = div.querySelectorAll('.srword');
        const srWordEnd = srWordList.length ? srWordList[srWordList.length - 1].getAttribute('audio-end-position') : null;

        if (this.speakerList.find((x1: any) => x1.id == 'S1') == undefined) {

          this.speakerList.push({
            name: 'Single Speaker',
            id: 'S1'
          });
        }
        let conversation = document.createElement('div');
        div.querySelectorAll('span').forEach((child, index) => {
          if (child.nodeName != "#text") {
            child.innerHTML = child.innerHTML + ' ';
            conversation.appendChild(child)
          }
        })
        this.transcriptionData.push(
          {
            word: conversation.innerText,
            confidence: '',
            formattedStart: formattedStart ? formattedStart : null,
            start: span0.getAttribute('audio-position') ? span0.getAttribute('audio-position') : srWordList[0].getAttribute('audio-position'),
            end: srWordEnd ? srWordEnd : null,
            speaker: {
              name: '(Single Speaker)',
              id: 'S1'
            },
            channel: '',
            alternateWords: [],
            spanString: conversation.innerHTML,
            speakingPara: false,
            allowActionOnPara: true
          }
        );
      }
    }

    if (editMode) {
      setTimeout(() => {
        //this.enableDisableEditor(true);
      }, 500);
    }
  }

  enableDisableEditor(enable) {
    if (enable) {
      this.editorInstance.forEach(editor => {
        editor.edit.on();
      })
    } else {
      this.editorInstance.forEach(editor => {
        editor.edit.off();
      })
    }
  }

  enableEdit(event) {
    this.myviqfroalaEditor.edit.on();
    this.isEditingEnabled = true;
    this.allowTranscriptEditing = false;
    this.sendNotification('You are now editing the document.', "info");
    this.EditorAutoSaveInterval = localStorage.getItem('EditorAutoSaveInterval');
    // Start the interval when the component initializes
    if (localStorage.getItem("allowTranscriptEditing") == 'true' && this.status == "DRAFTAVAILABLE" && this.isEditingEnabled) {
      this.intervalId = setInterval(() => {
        this.counter++;
        this.isAutoSaveMessage = true;
        // Auto-save logic here
        this.alertMode = "savedoc";
        this.saveChanges(false);
        // Use change detection if necessary
        //this.changeDetectorRef.detectChanges();
      }, this.EditorAutoSaveInterval * 1000); // Interval set to 90sec (900000ms)
    }
  }

  async saveChanges(isFormattable: boolean) {
    var transcriptrequest: transcriptionrequest = new transcriptionrequest();
    transcriptrequest.jobId = this.NsJobId.toString();
    transcriptrequest.transcript = this.myviqfroalaEditor.html.get();
    const parser = new DOMParser();
    const doc = parser.parseFromString(transcriptrequest.transcript, 'text/html');

    doc.querySelectorAll('span.bouncingBall').forEach((bouncingBall) => {
      const parentSpan = bouncingBall.parentElement;
      let text = bouncingBall.textContent;
      let textNode = document.createTextNode(text);
      parentSpan.replaceChild(textNode, bouncingBall);
    });
    const serializer = new XMLSerializer();
    transcriptrequest.transcript = serializer.serializeToString(doc);
    console.log("before save final transcriptrequest: ", transcriptrequest);
    var transcriptrequestjson = JSON.stringify(transcriptrequest)
    if (transcriptrequest.transcript === null || transcriptrequest.transcript === "") {
      Swal.fire('Empty Document cannot be sent for re-publishing', '', 'error')
    }
    else {
      await this.backendService.myviqPostAndFormatHtmlFileForExistingJob(transcriptrequest.jobId, transcriptrequest.transcript, isFormattable).subscribe((result: any) => {
        var transcriptionresult = this.backendService.getJSONResponse(result);
        console.log("transcriptionresult: ", transcriptionresult);
        if (this.alertMode == "savedoc") {
          // this.sendNotification('Document saved succefully.', 'success');
          (this.isAutoSaveMessage) ? this.sendNotification('Your document has been saved.', 'success') : Swal.fire('Your document has been saved.', '', 'success')
        }
        if (this.alertMode == "saveNExit") {
          Swal.fire('Document sent for re-publishing', '', 'success')
          this.router.navigateByUrl("/admin/job-list");
        }
      },
        err => {
          const er = this.backendService.getJSONResponse(err);
          console.log("re-publishing failed: ", er);
          (this.isAutoSaveMessage) ? this.sendNotification('Document sent for re-publishing failed', 'error') : Swal.fire('Document sent for re-publishing failed ', '', 'error')
          //this.sendNotification('Document update failed.', 'error');
          this.spinner.hide();
        })
    }
    //Save the draft using NS API call with send to formating = false.
  }
  public async downloadImageClick() {
    this.spinner.show();
    this.audioDownload(this.NcJobId);
  }
  async audioDownload(value: any) {

    const downloadAudioData = this.getJSONResponse(await this.backendService.getAudioDownloadURLByJobId(value).toPromise());
    for (let i = 0; i < downloadAudioData.data.audioDownloadUrls.length; i++) {
      let url = downloadAudioData.data.audioDownloadUrls[i];
      let filename = downloadAudioData.data.audioFileNames[i];
      this.fileDownloadLoop(url, filename, true);
    }
  }
  async fileDownloadLoop(url, fileName, IsProgress) {
    this.spinner.show();
    (await this.fileaccess.downloadWithProgress(url, fileName, IsProgress)).subscribe({
      next: (event: any) => {
        if (event.type === 'progress') {
          this.progress = event.value;
        } else if (event.type === 'response') {
          this.spinner.hide();
          this.progress = 0
          this.filesize = event.value.data.size;

          this.sendNotification(fileName + ' - File Downloaded successfully.', 'success');
        }
      },
      error: (err) => {
        this.spinner.hide();
        console.log(err);
        this.sendNotification('Downloded failed.', 'error');
      }
    });
  }
  // calcProgress(fileObj, IsProgress, progressEvent) {
  //   if (IsProgress) {
  //     this.progress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
  //   }
  // }
  saveNExit(event) {

    //Ask for the confirmation to save and submit the document to NS.
    this.alertMode = "saveNExit";
    this.sweetalert();
  }

  getJSONResponse(value: any) {
    return JSON.parse(JSON.stringify(value));
  }
  savedoc(event) {
    //save the draft to NS using the API call... with formating enabled.
    this.isAutoSaveMessage = false;
    this.alertMode = "savedoc";
    this.saveChanges(false);

  }

  exitEditor(event) {
    this.fileToPlay = "";
    if (this.isEditingEnabled) {
      this.alertMode = "exit";
      this.sweetalert();
    } else {
      this.router.navigateByUrl("/admin/job-list");
    }

  }

  sweetalert() {
    var reqsubstring = "";
    // if (this.alertMode == "saveToNS") {
    //   reqsubstring = "Do you really want to submit the Document? ";
    // }
    if (this.alertMode == "saveNExit") {
      reqsubstring = "Do you want to save and re-publish the document? ";
    }
    if (this.alertMode == "exit") {
      reqsubstring = "Are you sure you want to close without saving?  You will lose any edits that have been done";
    }
    if (this.alertMode == "FindReplace") {
      reqsubstring = "We've reached the end of the document. Do you want to continue searching for the beginning? ";
    }
    Swal.fire({
      title: 'Confirmation',
      text: reqsubstring,
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirm`,
      denyButtonText: `Cancel`,
      confirmButtonColor: '#187EB1',
      cancelButtonColor: '#5b5b5b',
    }).then((result) => {
      if (result.value) {
        if (this.alertMode == "saveNExit") {
          this.isAutoSaveMessage = false;
          this.onAudioPause(event)
          this.saveChanges(true);
          //this.isEditingEnabled = false;
        }
        if (this.alertMode == "exit") {
          this.router.navigateByUrl("/admin/job-list");
          //this.isEditingEnabled = false;
        }
        if (this.alertMode == "FindReplace") {
          this.currentIndex = -1;
          this.onFindText();
        }
      }
    })
  }

  getFormatedSecond(second = 0, isFormat = false): string {
    let formattedStart = '';
    const seconds = second;
    if (seconds > 60) {
      const min = parseInt(new Number(seconds / 60 % 60).toString());
      const sec = parseInt(new Number(seconds % 60 % 60).toString());
      if ((seconds / 60) > 60) {
        const hr = parseInt(new Number(seconds / 60 / 60).toString());
        formattedStart = `${hr}:${min > 9 ? min : '0' + min}:${sec > 9 ? sec : '0' + sec}`;
      } else {
        if (isFormat) {
          formattedStart = `${min > 9 ? min : '0' + min}:${sec > 9 ? sec : '0' + sec}`;
        } else {
          formattedStart = `00:${min > 9 ? min : '0' + min}:${sec > 9 ? sec : '0' + sec}`;
        }
      }
    } else {
      if (isFormat) {
        formattedStart = `00:${String(parseInt(new Number(seconds).toString())).length == 1 ? '0' + parseInt(new Number(seconds).toString()) : parseInt(new Number(seconds).toString())}`;
      } else {
        formattedStart = `00:00:${String(parseInt(new Number(seconds).toString())).length == 1 ? '0' + parseInt(new Number(seconds).toString()) : parseInt(new Number(seconds).toString())}`;
      }
    }
    return formattedStart;
  }
  toggleAllCaps() {
    console.log("allcaps clicked text");
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      let commonAncestorContainer = range.commonAncestorContainer as HTMLElement;
      let startContainer = range.startContainer as HTMLElement;
      let endContainer = range.endContainer as HTMLElement;
      // If the selection is inside a text node, get the parent element (the <span>)
      if (startContainer.nodeType === Node.TEXT_NODE) {
        startContainer = startContainer.parentNode as HTMLElement;;
      }
      if (endContainer.nodeType === Node.TEXT_NODE) {
        endContainer = endContainer.parentNode as HTMLElement;;
      }
      if (range.commonAncestorContainer.textContent == range.startContainer.textContent && range.commonAncestorContainer.textContent == range.endContainer.textContent) {
        let selectedSpan = range.commonAncestorContainer;
        if (selectedSpan) {
          const selectedText = selectedSpan.textContent;
          const spanContent = selectedSpan.textContent;
          const updatedSpanContent = spanContent.toUpperCase();
          selectedSpan.textContent = updatedSpanContent; // Update the span content
          // Restore the selection to the original range after replacement
          selection.removeAllRanges();  // Clear existing selections
          selection.addRange(range);  // Restore the original selection range
        }
      } else if (commonAncestorContainer.querySelectorAll('span.srword')) {
        const allSpans = Array.from(commonAncestorContainer.querySelectorAll('span.srword'));
        const startIndex = allSpans.indexOf(startContainer);
        const endIndex = allSpans.indexOf(endContainer);

        if (startIndex === -1 || endIndex === -1) {
          console.error('Start or end span not found within common ancestor.');
          return;
        }

        const spansBetween = allSpans.slice(startIndex, endIndex + 1);

        // Now spansBetween contains all <span> elements between 'parts' and 'world,' inclusive
        console.log(spansBetween);
        spansBetween.forEach(span => {
          span.textContent = span.textContent.toUpperCase();
        });

        // Restore the selection to the original range
        selection.removeAllRanges();  // Clear existing selections
        selection.addRange(range);  // Restore the original selection range
      }
    }
  }
  toggleFindReplace() {
    console.log("Find::: ");
    if (this.foundMatches.length > 0) {
      this.onClose();
    }
    // Get the selection
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      let commonAncestor = range.commonAncestorContainer;
      // Ensure commonAncestor is a Node and adjust to find the nearest <span>
      if (commonAncestor.nodeType === 3) {
        // If it's a Text node, get the parent
        commonAncestor = commonAncestor.parentNode;
      }
      // Check if the commonAncestor is a <span> or find the nearest <span> ancestor
      let selectedSpan = commonAncestor;
      while (selectedSpan && selectedSpan.nodeName !== 'SPAN') {
        selectedSpan = selectedSpan.parentNode;
      }
      this.customWindow.open();
      // Now selectedSpan will be the closest <span> or null if none found
      if (selectedSpan) {
        const selectedText = selectedSpan.textContent;  // Get the text content of the selected span
        console.log('Selected Span:', selectedSpan);
        console.log('Selected Text:', selectedText);
        this.findReplaceForm.controls.findText.setValue(selectedSpan.textContent.toString());
      } else {
        console.log('No span tag found in the selection.');
      }
    }
  }
  onFindText() {
    console.log("onFindText starts...");
    const findText = this.findReplaceForm.controls.findText.value;
    const replaceText = this.findReplaceForm.controls.replaceText.value;
    const isMatchCase = this.findReplaceForm.controls.isMatchCase.value;
    const isMatchWord = this.findReplaceForm.controls.isMatchWord.value;
    var Action = "FindText";
    if (findText == "") {
      this.sendNotification("Please enter the value in find text area.", 'error')
    } else {
      this.FindReplace(findText, replaceText, isMatchCase, isMatchWord, Action);
    }
  }
  onReplace(): void {
    const findText = this.findReplaceForm.controls.findText.value;
    const replaceText = this.findReplaceForm.controls.replaceText.value;
    const isMatchCase = this.findReplaceForm.controls.isMatchCase.value;
    const isMatchWord = this.findReplaceForm.controls.isMatchWord.value;
    var Action = "Replace";
    if (replaceText.trim() !== '') {
      this.FindReplace(findText, replaceText, isMatchCase, isMatchWord, Action);
    } else {
      this.sendNotification("Please enter text to replace with.", "error");
    }
  }
  onReplaceAll(): void {
    const findText = this.findReplaceForm.controls.findText.value;
    const replaceText = this.findReplaceForm.controls.replaceText.value;
    const isMatchCase = this.findReplaceForm.controls.isMatchCase.value;
    const isMatchWord = this.findReplaceForm.controls.isMatchWord.value;
    var Action = "ReplaceAll";
    if (replaceText.trim() !== '') {
      this.FindReplace(findText, replaceText, isMatchCase, isMatchWord, Action);
    } else {
      this.sendNotification("Please enter text to replace with.", "error");
    }

  }
  onClose(): void {
    const parser = new DOMParser();
    const doc = parser.parseFromString(this.transcription, 'text/html');
    let spanElements = doc.querySelectorAll('span');
    spanElements.forEach(span => {
      span.style.backgroundColor = '';
      span.style.color = 'black';  // Assuming the default text color is black
    });
    const updatedHtml = new XMLSerializer().serializeToString(doc);
    this.transcription = updatedHtml;
    this.findReplaceForm.controls.findText.setValue("");
    this.findReplaceForm.controls.replaceText.setValue("");
    this.findReplaceForm.controls.isMatchWord.setValue(false);
    this.findReplaceForm.controls.isMatchCase.setValue(false);
    this.currentIndex = 0;
    this.foundMatches = [];
    this.customWindow.close();
  }
  async FindTheText(spanElements: any, exp: any) {
    if (this.foundMatches.length == 0) {
      var counter = 0;
      spanElements.forEach((element, index) => {
        let spanText = element.textContent;
        if (exp.exec(spanText)) {
          index = counter == 0 ? index : index + 1;
          this.foundMatches.push({ element, index });
          counter++;
        }
      });
      if (this.foundMatches.length == 0) {
        this.sendNotification("No matches found", "error");
        return;
      }
    }
    else if (this.foundMatches.length > 0) {
      if (this.foundMatches.length == this.currentIndex + 1) {
        this.sendNotification("No more matches found", "error");
        return;
      }
      this.currentIndex = this.currentIndex + 1;
    }
    console.log("Foundmatches count", this.foundMatches);

  }
  async FindReplace(findText: string, replaceText: string, MatchCase: boolean, isMatchWord: boolean, ButtonAction: string) {
    let word = findText;
    let replace = replaceText;
    let isMatchCase = MatchCase;
    let Action = ButtonAction;
    let exp = isMatchCase ? new RegExp("\\b" + word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') + "(?=[.\\s]|$)", "") : new RegExp("\\b" + word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') + "(?=[.\\s]|$)", "i");
    const parser = new DOMParser();
    const doc = parser.parseFromString(this.transcription, 'text/html');
    let spanElements = doc.querySelectorAll('span.srword[id^="srword_"]');
    if (Action == 'FindText') {
      if (this.foundMatches.length == this.currentIndex + 1) {
        this.alertMode = "FindReplace";
        this.sweetalert();
      }
      else {
        await this.FindTheText(spanElements, exp);
        // Assuming you have this.foundMatches and spanElementArray defined
        let ind = this.foundMatches[this.currentIndex]; // Get the current found match
        const spanIdToFind = ind.element.getAttribute('id'); // e.g., "srword_31"
        let matchingSpan;
        // Case-sensitive match
        if (MatchCase) {
          matchingSpan = Array.from(spanElements).find(span =>
            span.id === spanIdToFind && span.textContent.trim() === word
          );
        }
        // Case-insensitive match
        else if (isMatchWord) {
          matchingSpan = Array.from(spanElements).find(span =>
            span.id === spanIdToFind && span.textContent.toLowerCase() === word.toLowerCase()
          );
        }
        else {
          matchingSpan = Array.from(spanElements).find(span =>
            span.id === spanIdToFind);
        }
        // const matchingSpan = Array.from(spanElements).find(span => span.textContent == word);
        if (matchingSpan) {
          // Remove previous highlights
          spanElements.forEach(span => {
            // Reset background color and text color for previously highlighted spans
            (span as HTMLElement).style.backgroundColor = '';
            (span as HTMLElement).style.color = 'black';
            // Remove any internal highlight spans
            span.innerHTML = span.innerHTML.replace(/<span style="background-color: blue; color: white">([^<]+)<\/span>/g, '$1');
          });

          // Highlight the found span element
          matchingSpan.style.backgroundColor = 'blue'; // Set new background color
          matchingSpan.style.color = 'white';
          // Perform the desired operation on the found span
          const spanText = matchingSpan.textContent; // Get the text content of the found span
          const exp = new RegExp(spanText, 'g'); // Create regex for the dynamic text
          // Highlight the matched text
          const updatedSpanContent = matchingSpan.innerHTML.replace(exp, (match) => {
            return `<span style="background-color: blue; color: white">${match}</span>`;
          });
          // Update the inner HTML of the found span
          matchingSpan.innerHTML = updatedSpanContent;
          // If needed, serialize the updated content back to HTML
          const updatedHtml = new XMLSerializer().serializeToString(doc);
          this.transcription = updatedHtml;  // Update your transcription content

        } else {
          console.log('No matching span found with the ID:', spanIdToFind);
        }
      }
    }
    else if (Action == 'Replace') {

      console.log("Replace");
      if (this.foundMatches.length == 0) {
        await this.FindTheText(spanElements, exp);
      }
      var ind = this.foundMatches[this.currentIndex]; // Get the current found match
      const spanIdToFind = ind.element.getAttribute('id'); // e.g., "srword_31"
      const matchingSpan = Array.from(spanElements).find(span => span.id === spanIdToFind);
      const spanContent = matchingSpan.textContent;
      const updatedSpanContent = spanContent.replace(spanContent, replace);
      matchingSpan.innerHTML = updatedSpanContent;
      const updatedHtml = new XMLSerializer().serializeToString(doc);
      this.transcription = updatedHtml;
      this.onFindText();
    }
    else if (Action == 'ReplaceAll') {
      console.log("ReplaceAll");
      if (this.foundMatches.length == 0) {
        await this.FindTheText(spanElements, exp);
      }
      this.foundMatches.forEach(mat => {
        //var ind = this.foundMatches[this.currentIndex]; // Get the current found match
        const spanIdToFind = mat.element.getAttribute('id'); // e.g., "srword_31"
        const matchingSpan = Array.from(spanElements).find(span => span.id === spanIdToFind);
        const spanContent = matchingSpan.textContent;
        const updatedSpanContent = spanContent.replace(spanContent, replace);
        matchingSpan.innerHTML = updatedSpanContent;

      });
      const updatedHtml = new XMLSerializer().serializeToString(doc);
      this.transcription = updatedHtml;
      this.currentIndex = 0;
      this.foundMatches = [];
    }
  }

  ngOnDestroy() {
    console.log("DESTROY")
    if (this.audioPlayer) {
      this.audioPlayer.src = "";
    }
    this.prevActiveTextId = 0;
    this.transcriptionData = [];
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.customWindow.close();
    this.isEditingEnabled = false;
  }
}
