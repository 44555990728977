import { jqxNotificationComponent } from 'jqwidgets-ng/jqxnotification';
import { BackendService } from './../../../services/backend.service';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, Inject, ViewChild, EventEmitter, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import axios from 'axios';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { environment } from "src/environments/environment";
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

let zipFile;
let ListzipFiles = [];
let ListZipFailedFiles;
let fileMinSize = environment.AudioFileSize;
let ListOfOriginalFiles = [];
let ListOfZipFiles = [];
let counters=0;
let countersAudioFileToBucket=0

@Component({
    selector: 'app-upload-audiofile',
    templateUrl: './upload-audiofile.component.html',
    styleUrls: ['./upload-audiofile.component.css']
})
export class UploadAudioFileComponent implements OnInit {
    @Output()
    notify: EventEmitter<string> = new EventEmitter<string>();

    progress: number;
    FileType = "";

    public originalFile: File;
    public ListOfNames: string;
    public isDuplicate: boolean;
    public ListOfAudioFiles: NgxFileDropEntry[] = [];
    returnUrl: string;
    canUpload: boolean = false;
    MediaFileUploadUrl = "";
    @ViewChild('file', { static: false }) file
    @ViewChild('msgNotification', { static: false }) msgNotification: jqxNotificationComponent;
    removable = true;
    constructor(private formBuilder: FormBuilder,
        private backendService: BackendService,
        public dialogRef: MatDialogRef<UploadAudioFileComponent>,
        private router: Router,
        private route: ActivatedRoute,
        private spinner: NgxSpinnerService,
        @Inject(MAT_DIALOG_DATA) public jobdata: any) { }


    ngOnInit() {
        // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/admin';
        // console.log("starting jobid:::" + this.jobdata.jobId);
        this.notify.emit("send data");
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
              // Call your dialogClose() method when navigating to a different tab
              this.dialogClose();
            }
          });
    }


    fileOver(event) {
        console.log('fileOver event : ' + event);
    }
    fileLeave(event) {
        console.log('fileLeave event : ' + event);
    }
    removeFileByName(fileName) {
        var ListAfterRemove = [];
        ListOfOriginalFiles.forEach(filedetails => {
            if (filedetails.name == fileName) {
                //  console.log("removed fileName:::" + fileName)
            }
            else {
                ListAfterRemove.push(filedetails);
            }
        });
        ListOfOriginalFiles = ListAfterRemove;
        this.ListOfAudioFiles = ListOfOriginalFiles;
        if (this.ListOfAudioFiles.length == 0) {
            this.canUpload = false;
            ListOfOriginalFiles = [];
            ListzipFiles = [];
        }
        this.file = null;
        this.originalFile = null;
    }
    public dropped(files: NgxFileDropEntry[]) {

        //this.ListOfAudioFiles = files;
        //ListzipFiles = [];
        for (const droppedFile of files) {
            // Is it a file?
            if (droppedFile.fileEntry.isFile) {
                this.isDuplicate = false;
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    this.file = file;
                    this.originalFile = file;
                    this.FileType = file.name.split(/[.;+_]/).pop().toLowerCase();

                    if (this.originalFile.size > fileMinSize) {
                        //if(this.FileType === "mp3" || this.FileType === "wav" || this.FileType === "mp2" || this.FileType === "m4a" || this.FileType === "m4v" || this.FileType === "dss" || this.FileType === "ds2" || this.FileType === "aac" || this.FileType === "wma" || this.FileType === "oga" || this.FileType === "trm" || this.FileType === "mp4" || this.FileType === "m4v" || this.FileType === "opus" || this.FileType === "avi"|| this.FileType === "zip")
                        if (this.FileType === "mp3" || this.FileType === "wav" || this.FileType === "mp2" || this.FileType === "m4a" || this.FileType === "m4v" || this.FileType === "dss" || this.FileType === "ds2" || this.FileType === "aac" || this.FileType === "wma" || this.FileType === "oga" || this.FileType === "trm" || this.FileType === "mp4" || this.FileType === "m4v" || this.FileType === "opus" || this.FileType === "avi" || this.FileType === "ogg" || this.FileType === "mkv" || this.FileType === "wmv" || this.FileType === "vob" ||  this.FileType === "mov" || this.FileType === "zip") {
                            //////For Zip format

                            if (this.FileType === "zip") {
                                this.sendNotification('Selected ' + this.FileType +' file Type is not supported', "error")
                            }
                            ///////Normal audio files
                            else {
                                ListOfZipFiles = [];
                                ///Duplicate filename  verification
                                ListOfOriginalFiles.forEach(filedetails => {
                                    if (filedetails.name == this.originalFile.name) {
                                        this.isDuplicate = true;
                                    }
                                })
                                ///
                                if (this.isDuplicate == true) {
                                    this.sendNotification(this.originalFile.name + " is already exist.", "error");
                                }
                                else {
                                    if (this.ListOfNames == "") {
                                        this.ListOfNames = this.originalFile.name;
                                    }
                                    else {
                                        this.ListOfNames = this.ListOfNames + ", " + this.originalFile.name;
                                    }
                                    ListOfOriginalFiles.push(this.originalFile);
                                    this.canUpload = true;
                                }
                               // this.ListOfAudioFiles = ListOfOriginalFiles;
                               this.ListOfAudioFiles=[ListOfOriginalFiles[0]];
                            }
                        }
                        else {
                            //  this.remove();
                            this.sendNotification('Selected file is ' + this.FileType + '. Please upload files with mp2, mp3, wav, m4a, dss, ds2, aac, wma, oga, trm,mod and zip formats.', 'error');
                        }
                    }
                    else {
                        // this.remove();
                        this.sendNotification('Please upload files with mininum 5kb size.', 'error');
                    }
                })
            }
        }
        this.ListOfAudioFiles=[ListOfOriginalFiles[0]];
    }
    sendNotification(message, messageType) {
        this.msgNotification.elementRef.nativeElement.querySelector('.jqx-notification-content').innerHTML = message;
        this.msgNotification.template(messageType);
        this.msgNotification.open();
    }

    dialogClose() {
        this.dialogRef.close();
        ListOfOriginalFiles = [];
        this.ListOfAudioFiles = [];
    }
    getJSONResponse(value: any) {
        return JSON.parse(JSON.stringify(value));
    }

    async uploadAudio() {
        this.spinner.show();
        console.log("jobid:::" + this.jobdata.jobId);
        let FileExtension = '';
        let audioFileName = "";
        audioFileName = ListOfOriginalFiles[0].name;
        FileExtension = ListOfOriginalFiles[0].name.split(/[.;+_]/).pop().toLowerCase();
        var UploadURLDetails = this.getJSONResponse(await this.backendService.getReUploadAudiofileURL(this.jobdata.jobId, FileExtension, audioFileName).toPromise()).data;
        console.log("ReuploadURL::" + JSON.stringify(UploadURLDetails));
        this.MediaFileUploadUrl = "";
        this.MediaFileUploadUrl = UploadURLDetails.mediaFileUploadUrl;
        this.uploadAudioFileToBucket(ListOfOriginalFiles[0], this.jobdata.jobId);
        this.backendService.AWSLog("info", "Re-Upload Job Process Starts... Non-ZipFile Process...Total files count :::" + ListOfOriginalFiles.length + "Audio File Details::: FileName::" + ListOfOriginalFiles[0].name +
        "  FileSize::" + ListOfOriginalFiles[0].size + "  FileType::" + ListOfOriginalFiles[0].type);
        this.canUpload = false;
        this.spinner.hide();
    }

    removeJobAudioFiles() {
        this.ListOfAudioFiles = [];
        //ListOfAudioFiles = new FileToUpload();
    };
    clear() {
        this.removeJobAudioFiles();
    }
    calcProgress(fileObj, progressEvent) {
        //console.log("fileObj:::" + fileObj + "progressEvent:::;" + progressEvent)
        this.progress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        //console.log("this.progress:::;" + this.progress)
    }

    async uploadAudioFileToBucket(filedetails, jobId) {
        this.progress = 0;
        this.backendService.AWSLog("info", "uploadAudioFileToBucket Method starts... FileDetails ::: FileName::" + filedetails.name +
            "  FileSize::" + filedetails.size + "  FileType::" + filedetails.type + " Jobid ::: " + jobId + "  MediaFileUploadUrl :::" + this.MediaFileUploadUrl).subscribe();

        // Progress bar
        //
        const fileObject = {
            name: filedetails.name,
            size: (filedetails.size / (1024 * 1024)).toFixed(2) + ' MB',
            progress: 0,
            path: null
        };

        axios.put(this.MediaFileUploadUrl, filedetails, {
            onUploadProgress: this.calcProgress.bind(this, fileObject)
        })
            .then(res => {
                console.log(res);
                countersAudioFileToBucket=countersAudioFileToBucket+1;
                this.progress = null;
                console.log("Audio file upload result:::", JSON.stringify(res.data));
                this.backendService.AWSLog("info", jobId + "Resubmit uploadAudioFileToBucket Method - Successfully Uploaded :::  " + JSON.stringify(res.data)).subscribe();
                this.dialogClose();
                Swal.fire('Audio file uploaded successfully.', '','success');
                this.clear();
                this.spinner.hide();
                // setTimeout(() => {
                //     // const JobListRefresh = document.getElementById('joblist-refresh');
                //     // (JobListRefresh.children[0] as HTMLElement).click();
                //     // this.spinner.hide();
                //     const jobstatusLink = document.getElementById('joblist-status-dropdown');
                //     const ele = (jobstatusLink.children[0] as HTMLElement);
                //     const jobrefreshLink = document.getElementById('joblist-refresh');
                //     (jobrefreshLink.children[0] as HTMLElement).click();
                // }, 5000);
            })
            .catch(err => {
                console.log(err);
                this.progress = null;
                Swal.fire('Audio file upload failed.', 'error');
                this.backendService.AWSLog("error", jobId + "Error - Resubmit uploadAudioFileToBucket Method :::  " + JSON.stringify(err)).subscribe();
                this.spinner.hide();
            });

    }

}

