import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { jqxNotificationComponent } from 'jqwidgets-ng/jqxnotification';
import { BackendService } from '../services/backend.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UserRegistrationService } from '../services/user-registration.service';
import { ResponseMessage } from '../models/ResponseMessage';
import { RegistrationUser, Organizations, } from 'src/app/models/apiRequestModels';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

let user: RegistrationUser = new RegistrationUser();

@Component({
  selector: 'app-user-profile',
  templateUrl: './user.profile.component.html',
  styleUrls: ['./user.profile.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class UserProfileComponent implements OnInit {

  @ViewChild('msgNotification', { static: false }) msgNotification: jqxNotificationComponent;

  //Profile fields
  activeTab = 'myProfile';
  userCreationForm: FormGroup;
  hasError: boolean;
  phoneinputObj: any;
  isMFA = false;

  //Change Password fields
  form: FormGroup;
  submitted = false;
  oldPassword: any;
  newPassword: any;
  confirmPassword: any;
  tabValue;
  isVisible:boolean=true;

  //help center fields
  contactEmail: string;
  contactNumber: string;
  showoldPassword: boolean = false;
  shownewPassword: boolean = false;
  showconfirmPassword: boolean = false;
  userTenantId:any;
  userHostTenants:any;

  //App Download fields
  brandname = localStorage.getItem('brand');
  NetScribefootPedalMSIURL: string = environment.footPedalDownloadUrl;
  NetScribeUploadSetupDownloadURL: string = environment.NSUploadSetupDownloadUrl;

  constructor(private formBuilder: FormBuilder,
    private backendService: BackendService,
    private userRegistration: UserRegistrationService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.spinner.show();
    var Isidpuser = localStorage.getItem('idpuser')
    if(Isidpuser == "true")
    {
        this.isVisible=false
    }
    const state = history.state;
    this.tabValue = state['tabValue'];
    console.log("tab value selected: ", this.tabValue);
    this.userCreationForm = this.formBuilder.group({
      email: new FormControl(''),
      firstName: new FormControl(''),
      middleName: new FormControl(''),
      lastName: new FormControl(''),
      phone: new FormControl(''),
      isMFA: new FormControl('false'),
      Isadmin: new FormControl('false'),
      allowTranscriptEditing : new FormControl('false')

    });
    this.route.queryParams.subscribe(params => {
      if (params.tabValue) {
        this.activeTab = params.tabValue;
      }
    });
    this.form = this.formBuilder.group({
      oldPassword: new FormControl(),
      newPassword: new FormControl(),
      confirmPassword: new FormControl()
    }, {
      validator: MustMatch('newPassword', 'confirmPassword')
    });

    this.getUserDetails();
    this.getHelpCenterInfo();
  }
  async loadUserDetailsByUserName(userName: string) {
    const userDetails = this.backendService.getJSONResponse(await this.backendService.getUserByUserName(userName).toPromise());
    return userDetails.data;
  }
  getUserDetails() {
    let CurrentUserName = localStorage.getItem('userName');
    this.loadUserDetailsByUserName(CurrentUserName).then(
      async res => {
        console.log("update Profile loadUserDetailsByUserName", res)
        //updating user model
        this.updateUserModel(res);
        this.userCreationForm.setValue({
          email: res.email,
          firstName: res.firstName,
          middleName: res.middleName,
          lastName: res.lastName,
          phone: res.mobileNo,
          isMFA: res.isMFA,
          Isadmin:res.Isadmin ? res.Isadmin : false,
          allowTranscriptEditing: res.allowTranscriptEditing
          
        });
        this.userTenantId=res.tenantRecordId;
        this.userHostTenants=res.hostTenants;
        if (this.userCreationForm.controls.phone.value != null) {
          this.phoneinputObj.setNumber(this.userCreationForm.controls.phone.value);
        }
       // this.userRegistration.admingetuser(CurrentUserName, this)
        this.spinner.hide();
      }
    )
  }
  mfauserFlagChangeEvent(event) {
    this.isMFA = event.checked;
}
  emailchangeEvent(event) {
    // console.log(event);
    // this.userCreationForm.controls.nsAuthor.setValue(false);
  }

  isValid(controlName, error) {
    return this.userCreationForm && this.userCreationForm.controls[controlName].hasError(error);
  }

  onError(event: any): void {
    this.hasError = event;
    if (!event && this.userCreationForm.value.phone !== '') {
      this.userCreationForm.get('phone').setErrors(['invalid_cell_phone', true]);
    }
  }

  getNumber(obj) {
    this.userCreationForm.controls.phone.setValue(obj);
    this.phoneinputObj.setNumber(this.userCreationForm.controls.phone.value);
  }

  onCountryChange(obj) {
  }

  telInputObject(obj) {
    this.phoneinputObj = obj;
    obj.setCountry('us');
  }
  updateUserModel(res) {
    // let user: RegistrationUser = new RegistrationUser();
    user.password = "";
    //user.organization = this.selectedOrganization;
    //user.organization.deptExtId = finalSelectedDepartments;
    user.ncUrl = res.webSite;
    user.name = res.userName;
    user.IsMMP = res.isMMP;
    user.IsMFA = res.isMFA;
    user.roleId = res.roleId;
    user.IsMMP = res.isMMP;
    user.IsSuperAuthor = res.isSuperAuthor;
    user.Isadmin = res.isAdmin;
    user.authorExtId = res.authorExtId;
    user.id = res.id;
    user.status = res.status;
    user.allowTranscriptEditing = res.allowTranscriptEditing;
    let org: Organizations = new Organizations();
    org.custExtId = res.custExtId;
    org.deptExtId = res.departments;
    user.organization = org;
  }

  updateUser() {
    console.log("updateUser starts...");  
    if(this.userCreationForm.controls.firstName.value == "" || this.userCreationForm.controls.firstName.value == null){
      this.sendNotification('Please enter FirstName.', 'error');
    }
    else if(!this.userCreationForm.controls.firstName.valid){
      this.sendNotification('Please enter a valid firstName.', 'error');
  }
    // else if (this.userCreationForm.controls.phone.value == "" || this.userCreationForm.controls.phone.value == null) {
    //   this.sendNotification('Please enter valid phone number.', 'error');
    // }
    else if (this.userCreationForm.controls.email.value == "" || this.userCreationForm.controls.email.value == null) {
      this.sendNotification('Please enter Email.', 'error');
    }
    else if(this.userCreationForm.controls.lastName.value == "" || this.userCreationForm.controls.lastName.value == null){
      this.sendNotification('Please enter LastName.', 'error');
    }
    else if(!this.userCreationForm.controls.lastName.valid){
        this.sendNotification('Please enter a valid lastName.', 'error');
    }
    else {
      this.spinner.show();
      user.email = this.userCreationForm.controls.email.value;
      user.firstName = this.userCreationForm.controls.firstName.value;
      user.middleName = this.userCreationForm.controls.middleName.value ? this.userCreationForm.controls.middleName.value : "";
      user.lastName = this.userCreationForm.controls.lastName.value;
      user.phone_number = this.userCreationForm.controls.phone.value;
      user.IsMFA = this.userCreationForm.controls.isMFA.value;
      user.Isadmin = this.userCreationForm.controls.Isadmin.value;
      user.allowTranscriptEditing= this.userCreationForm.controls.allowTranscriptEditing.value
      user.EmailProfile = "1";
      user.addNSAuthor = false;
      user.isActive = true;
      user.password = "123";
      user.resend = false;
      user.TenantId=this.userTenantId;
      user.hostTenants=this.userHostTenants
      if(this.userCreationForm.controls.isMFA.value && (this.userCreationForm.controls.phone.value == ""  || this.userCreationForm.controls.phone.value == null)){
        this.sendNotification('Phone number is mandatory for MFA.', 'error');
        this.spinner.hide()
     } else if((this.userCreationForm.controls.isMFA.value == false && this.userCreationForm.controls.phone.value != "") || (this.userCreationForm.controls.isMFA.value == false && this.userCreationForm.controls.phone.value == "")) {
        user.phone_number = this.userCreationForm.controls.phone.value
        this.userRegistration.adminUpdateUserAttributes(user, this);
     } else {
      console.log("user Model", user);
      this.userRegistration.adminUpdateUserAttributes(user, this);
     }

    }


  }
  cognitoCallback(message: string, result: any) {
    if (result != null && result.statusCode == 400) {
      this.sendNotification(message + " _ " + JSON.stringify(result.message), 'error');
      this.spinner.hide();
    }

    if (message == "User Updated Successfully") {
      this.sendNotification(JSON.stringify(message), 'success');
      this.spinner.hide();
    }
    if (message == "Get User data Successfully") {
      const userData = this.backendService.getJSONResponse(result);
      console.log("userData result", result);
      let email = '';

      //this.UserStatus = userData.UserStatus;
      for (let obj of userData.UserAttributes) {
        if (obj.Name == 'email') {
          email = obj.Value;
        }
      }
      //console.log("UserStatus", this.UserStatus);
      this.userCreationForm.controls.email.setValue(email);
    }
  }
  editCancel() {

  }

  //Change Password Implementation starts here.... 

  sendNotification(message, messageType) {
    this.msgNotification.elementRef.nativeElement.querySelector('.jqx-notification-content').innerHTML = message;
    this.msgNotification.template(messageType);
    this.msgNotification.open();
  }
  AvoidSpace() {
    // this.sendNotification("Please enter the valid special character(Space is not allowed).", "error")
    return false;
  }

  get f() {
    return this.form.controls;
  }
  isPasswordMatch = false;

  newPasswordChangeEvent() {
    const upperCase = document.getElementById('pwd-upper-case');
    const number = document.getElementById('pwd-number');
    const characters = document.getElementById('pwd-characters');
    const lowerCase = document.getElementById('pwd-lower-case');
    const specialCharacters = document.getElementById('pwd-special-characters');
    const value = this.form.controls.newPassword.value;

    if (value) {
      this.isPasswordMatch = true;
      if (value.match('(.*[A-Z])')) {

        upperCase.classList.add('password-text-underline');
      } else {
        this.isPasswordMatch = false;
        upperCase.classList.remove('password-text-underline');
      }

      if (value.match('(.*[0-9])')) {
        // this.isPasswordMatch = true;
        number.classList.add('password-text-underline');
      } else {
        this.isPasswordMatch = false;
        number.classList.remove('password-text-underline');
      }

      if (value.match('(.*[a-z])')) {
        // this.isPasswordMatch = true;
        lowerCase.classList.add('password-text-underline');
      } else {
        this.isPasswordMatch = false;
        lowerCase.classList.remove('password-text-underline');
      }

      if (value.match(/^[!@#$%^&*?a-zA-Z0-9]+$/)) {
        // this.isPasswordMatch = true;
        specialCharacters.classList.add('password-text-underline');
      } else {
        this.isPasswordMatch = false;
        specialCharacters.classList.remove('password-text-underline');
      }

      if (value.length >= 8) {
        // this.isPasswordMatch = true;
        characters.classList.add('password-text-underline');
      } else {
        this.isPasswordMatch = false;
        characters.classList.remove('password-text-underline');
      }
    }
    if (value.length == 0) {
      this.isPasswordMatch = false;
      upperCase.classList.remove('password-text-underline');
      number.classList.remove('password-text-underline');
      characters.classList.remove('password-text-underline');
      lowerCase.classList.remove('password-text-underline');
      specialCharacters.classList.remove('password-text-underline');
    }

  }

  isChangePasswordValid(controlName, error) {
    return this.form.controls[controlName].hasError(error);
  }
  clearChangePasswordData(){
    this.form.reset()
  }
  passwordToggleoldPassword() {
    this.showoldPassword = !this.showoldPassword;
  }
  passwordTogglenewPassword() {
    this.shownewPassword = !this.shownewPassword;
  }
  passwordToggleconfirmPassword() {
    this.showconfirmPassword = !this.showconfirmPassword;
  }
  changePassword() {

    this.oldPassword = this.form.controls.oldPassword;
    this.newPassword = this.form.controls.newPassword;
    this.confirmPassword = this.form.controls.confirmPassword;
    if (this.oldPassword.value === null) {
      this.sendNotification("Please enter old password", 'error');
    }
    else if (this.newPassword.value === null) {
      this.sendNotification("Please enter new password", 'error');
    }
    else if (this.confirmPassword.value === null) {
      this.sendNotification("Please enter confirm password", 'error');
    }
    else if (!this.isPasswordMatch) {
      this.sendNotification('Please follow the password rules.', 'error');
    }
    else {
      this.submitted = true;
      if (this.submitted && this.form.valid && this.isPasswordMatch) {
        this.spinner.show();
        const data = {
          'userName': localStorage.getItem('userName'),
          'password': this.oldPassword.value,
          'newpassword': this.newPassword.value,
          'accessToken': localStorage.getItem('accessToken')
        }
        this.backendService.changePassword(data).subscribe(
          res => {
            const result = this.backendService.getJSONResponse(res);
            if (result != null) {
              console.log("ChangePassword Result:::", result);
              if (result.data.isSuccess == true) {
                if (result.data.userStatus == ResponseMessage.PASSWORD_UPDATED) {
                  this.submitted = false;
                  this.sendNotification("Password Updated. Please log in with the new password.", 'success');
                  // this.dialogRef.close();
                  console.log('force change password results :: ', res);
                  //Log out
                  this.backendService.isLoggedIn = false;
                  this.backendService.setUserLoggedIn(false);
                  this.spinner.hide();
                }
              }
              else {
                if (result.data.userStatus == ResponseMessage.UNABLE_TO_UPDATED) {
                  this.submitted = false;
                  this.sendNotification(ResponseMessage.UNABLE_TO_UPDATED, 'error');
                  this.spinner.hide();
                }
                if (result.data.userStatus == ResponseMessage.OLDPASSWORD_MATCHED) {
                  this.submitted = false;
                  this.sendNotification("Entered password is being used in the last 5 Instances, please use a new password.", 'error');
                  this.spinner.hide();
                }
                if (result.data.userStatus == ResponseMessage.PASSWORDCHANGE_UNSUCCESSFUL) {
                  this.submitted = false;
                  this.sendNotification(ResponseMessage.PASSWORDCHANGE_UNSUCCESSFUL, 'error');
                  this.spinner.hide();
                }
                if (result.data.userStatus == ResponseMessage.INCORRECT_USERNAME_PASSWORD) {
                  this.submitted = false;
                  this.sendNotification('INCORRECT_OLD _PASSWORD', 'error');
                  this.spinner.hide();
                }
                if (result.data.userStatus == ResponseMessage.USER_NOT_FOUND) {
                  this.submitted = false;
                  this.sendNotification(ResponseMessage.USER_NOT_FOUND, 'error');
                  this.spinner.hide();
                }
                if (result.data.userStatus == ResponseMessage.LimitExceededException) {
                  this.submitted = false;
                  this.sendNotification(ResponseMessage.LimitExceededException + ". Attempt limit exceeded, please try after 30 Mins.", 'error');
                  this.spinner.hide();
                }
                if (result.data.userStatus == ResponseMessage.InvalidPasswordException) {
                  this.submitted = false;
                  this.sendNotification(ResponseMessage.InvalidPasswordException + ". Password contains unsupportable special characters. Please check.", 'error');
                  this.spinner.hide();
                }
                if (result.data.userStatus == ResponseMessage.TooManyRequestsException) {
                  this.submitted = false;
                  this.sendNotification(ResponseMessage.TooManyRequestsException + ".", 'error');
                  this.spinner.hide();
                }
                if (result.data.userStatus == ResponseMessage.InternalErrorException) {
                  this.submitted = false;
                  this.sendNotification(ResponseMessage.InternalErrorException + ".", 'error');
                  this.spinner.hide();
                }
                if (result.data.userStatus == ResponseMessage.InvalidParameterException) {
                  this.submitted = false;
                  this.sendNotification(ResponseMessage.InvalidParameterException + ".", 'error');
                  this.spinner.hide();
                }
                if (result.data.userStatus == ResponseMessage.ERROR) {
                  this.submitted = false;
                  this.sendNotification(ResponseMessage.ERROR + ". Failed to update the password.", 'error');
                  this.spinner.hide();
                }
              }
            }
          },
          err => {
            var errormessage = "";
            console.log('error occured while changing the password', err.error.data.message)
            if (err.status == 401) {
              if (err.error.data.message = "Incorrect username or password.") {
                errormessage = "Incorrect Old Password.";
              }
              this.sendNotification(errormessage, 'error');
            }
            this.spinner.hide();
          }
        );
      }
    }
  }

  getHelpCenterInfo() {
    this.spinner.show();
    let username = localStorage.getItem('user_attribute');
    this.backendService.getsupportdetailsbyusername(username).subscribe(
      res => {
        console.log("authnticate result...", res);
        const result = this.backendService.getJSONResponse(res);
        this.contactEmail = result.data.supportEmail;
        this.contactNumber = result.data.supportContactNo;
        this.spinner.hide();
      },
    )
  }
}

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }
    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}