<h1 mat-dialog-title> <img class="img-icon mr-2" alt="Replace Audio" src="../../../assets/img/icon/replace.png" />
  Replace Audio File:<button mat-dialog-close type="button" class="close pull-right"
    (click)='dialogClose()' aria-label="Close">&times;</button></h1>
<div class="progress" *ngIf="progress">
  <div class="progress-bar" [style.width]="progress + '%'">{{progress}}%</div>
</div>
<hr>
<div mat-dialog-content>
  <div class="row">
    <div class="col-md-12" style="text-align: center;">
      <ngx-file-drop  id="dropZone" dropZoneLabel="Drop files here"
        (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)"
        (multiple)="true">
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
          <div class="col-sm-6" style="margin-top: 5px;">
            <p class="header-text-1">Drag and drop a file here to upload </p>
          </div>
          <div class="col-sm-6 ">
            <button type="button" class="btn btn-outline-primary btn-viq-primary-outline attachment-btn attachment-icon"
              (click)="openFileSelector()">
              <i class="fa fa-folder-open mr-1"> </i>
              Select Audio/Video </button>
          </div>
          <div class="col-sm-6">
            <span class="upload-Audio-formats-text" style="color: rgb(255, 82, 82);">
              Please upload file with
              mp2/mp3/mp4/wav/m4a/m4v/ dss/ds2/aac/wma/oga/trm/ opus/avi/ogg/mkv/wmv/vob/mov/mts/zip/mod
              formats.</span>
          </div>
        </ng-template>
      </ngx-file-drop>
    </div>
    <div class="col-md-12 mt-2">
      <p class="header-text-2">Selected Audio/Video File: </p>
      <hr>
      <div class='col-md-12'>
        <div style="overflow-y: hidden; overflow-x: hidden;  ">
          <mat-chip-list aria-hidden="true">
            <mat-chip  *ngFor="let item of ListOfAudioFiles" [selectable]="selectable" [removable]="removable"
              (removed)="removeFileByName(item.name)">
              {{ item.name }}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
    </div>
  </div>
</div>

<mat-dialog-actions align="end" style="margin-right: 20px; margin-bottom: 2px;">
  <button class='btn btn-viq btn-viq-primary pull-right'
    (click)='uploadAudio()'>Submit</button>
  <button class='btn btn-viq-secondary pull-right ml-1' 
    (click)='dialogClose()'>Cancel</button>
</mat-dialog-actions>

<jqxNotification #msgNotification [position]="'top-right'" [opacity]="1.0" [autoOpen]="false"
    [autoClose]="true" [animationOpenDelay]="800" [autoCloseDelay]="5000" template.success>
</jqxNotification>