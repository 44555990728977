<nav #navbar class="navbar navbar-expand-lg navbar-absolute">
  <div class="row" style="position: relative;">
  <app-notification-banner
    *ngIf="isBannarEnable"
      [message]="bannerMessage"
    ></app-notification-banner>
    <button *ngIf="isBannarEnable" class="icon-button" (click)="closeBanner()">
      <mat-icon >cancel</mat-icon>
    </button>
  </div>
  <div class="container-fluid">
    
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button mat-raised-button (click)="minimizeSidebar()" class="btn btn-just-icon btn-white btn-fab btn-round">
          <i class="material-icons text_align-center visible-on-sidebar-regular">toggle_on</i>
          <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">toggle_off</i>
        </button>
      </div>
      <a class="navbar-brand navbar-text" href="{{getPath()}}"> {{getTitle()}}</a>
    </div>
    <div class="navbar-toggler btn-no-ripple">
      <a class="nav-link" href="" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
        aria-expanded="false">
        <span class="sr-only">Toggle navigation</span>
        <span class="navbar-toggler-icon icon-bar"></span>
        <span class="navbar-toggler-icon icon-bar"></span>
        <span class="navbar-toggler-icon icon-bar"></span>
      </a>
      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
        <a class="dropdown-item btn-viq dropdownitemclass" data-controls-modal="#change-password" data-backdrop="true"
          data-keyboard="false" data-toggle="modal" data-target="#change-password" (click)="openDialog()">Change
          Password</a>
        <a class="dropdown-item btn-viq dropdownitemclass" (click)='logout()'>Log Out</a>
      </div>
    </div>
    <!-- <button mat-button class="navbar-toggler btn-no-ripple" type="button" (click)="sidebarToggle()">
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button> -->
    
    <div class="collapse navbar-collapse justify-content-end" id="navigation">
      <!-- <form class="navbar-form">
        <div class="input-group no-border">
          <input type="text" value="" class="form-control" placeholder="Search...">
          <button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
            <i class="material-icons">search</i>
            <div class="ripple-container"></div>
          </button>
        </div>
      </form> -->
    
      <!-- <div class="row">
        <app-notification-banner
        *ngIf="isBannarEnable"
          [message]="bannerMessage"
        ></app-notification-banner>
      </div>
      <div class="row banner-close-button">
        <button *ngIf="isBannarEnable" class="icon-button" (click)="closeBanner()">
          <mat-icon >cancel</mat-icon>
        </button>
      </div> -->
      <ul class="navbar-nav">
        <!-- <li class="nav-item">
          <a class="nav-link" href="#pablo">
            <i class="material-icons">dashboard</i>
            <p>
              <span class="d-lg-none d-md-block">Stats</span>
            </p>
          </a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" href="#pablo" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="material-icons">notifications</i>
            <span class="notification">5</span>
            <p>
              <span class="d-lg-none d-md-block">Some Actions</span>
            </p>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" href="#">Mike John responded to your email</a>
            <a class="dropdown-item" href="#">You have 5 new tasks</a>
            <a class="dropdown-item" href="#">You're now friend with Andrew</a>
            <a class="dropdown-item" href="#">Another Notification</a>
            <a class="dropdown-item" href="#">Another One</a>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#pablo">
            <i class="material-icons">person</i>
            <p>
              <span class="d-lg-none d-md-block">Account</span>
            </p>
          </a>
        </li> -->
        <li class="nav-item profile-css">
          <div class="row justify-content-end">
            <div class="col-md-12 navbar-text-profile">Welcome, {{getUsername()}}</div>
            <span class="navbar-text-role mr-3">{{ getUserRole() }}</span>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" href="" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            <i class="material-icons account-icon">account_circle</i>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item btn-viq" routerLink="/admin/user-profile" [queryParams]="{ tabValue: 'myProfile' }" [state]="{tabValue: 'myProfile'}">
              <div class="d-flex align-items-center">
                <i class="material-icons-outlined mr-3">contact_emergency</i><span>My Profile</span>
              </div>
            </a>
            <a class="dropdown-item btn-viq" routerLink="/admin/user-profile" [queryParams]="{ tabValue: 'changePassword' }" [state]="{tabValue: 'changePassword'}">
              <div class="d-flex align-items-center" *ngIf="isVisible">
                <i class="material-icons-outlined mr-3">password</i><span>Change Password</span>
              </div>
            </a>
            <a class="dropdown-item btn-viq" routerLink="/admin/user-profile" [queryParams]="{ tabValue: 'helpCenter' }" [state]="{tabValue: 'helpCenter'}">
              <div class="d-flex align-items-center">
                <i class="material-icons-outlined mr-3">help_outline</i><span>Help Center</span>
              </div>
            </a>
            <a class="dropdown-item btn-viq" routerLink="/admin/user-profile" [queryParams]="{ tabValue: 'appDownloads' }" [state]="{tabValue: 'appDownloads'}">
              <div class="d-flex align-items-center">
                <i class="material-icons-outlined mr-3">browser_updated</i><span>Download NetScribe Utilities</span>
              </div>
            </a>
            <a class="dropdown-item btn-viq" (click)="downloadReleaseNotes()" routerLink="">
              <div class="d-flex align-items-center">
                <i class="material-symbols-outlined mr-3">export_notes</i><span>Release Notes</span>
              </div>
            </a>
            <hr>
            <!-- <a class="dropdown-item btn-viq" data-controls-modal="#change-password" data-backdrop="true"
              data-keyboard="false" data-toggle="modal" data-target="#change-password" (click)="openDialog()">Change
              Password</a> -->
            <a class="dropdown-item btn-viq" (click)='logout()' routerLink="">
              <div class="d-flex align-items-center">
                <i class="material-icons-outlined mr-3">logout</i><span>Log Out</span>
              </div>
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
<jqxNotification #msgNotification [position]="'top-right'" [opacity]="1.0" [autoOpen]="false" [autoClose]="true"
  [animationOpenDelay]="800" [autoCloseDelay]="5000" template.success>
</jqxNotification>